import React, { useEffect } from "react";

import useGetReduxState from "../../../../../Hooks/useGetReduxState";

import BookingSteps from "../../../../../Templates/BookingSteps";
import NextSteps from "../../../../../Templates/NextSteps";

import ReviewJourney from "../../../../../Templates/ReviewJourney";
import Alert from "../../../../../UI/UIComponents/ErrorAlert/Alert";
import Spinner from "../../../../../UI/UIComponents/Spinner";

import useBookingFlow from "../../../../../Hooks/useBookingFlow";
import Captcha from "../../../../../Templates/Captcha";
import OtpVerify from "../../../../../Templates/OTPVerify";

const langText = {
  en: {
    back: "Back",
    continue: "Continue",
  },
  hi: {
    back: "वापस जाएं",
    continue: "आगे बढ़ें",
  },
  gu: {
    back: "પાછા",

    continue: "ચાલુ રાખો",
  },
};

const PassengerFormButtons = () => {
  const lang: "hi" | "en" | "gu" = useGetReduxState().behaviour.lang;
  const {
    currentPassengerFormStep,
    // currentBookingStep,
    loading,
    handlePassengerFormStepBack,

    error,
    subError,
    handleSubmitPassengers,
    handleSubmitPreferences,
    handleErrorClose,
    showReview,
    reviewData,
    handleCloseReview,
    submitReview,
    // showBookingSteps,
    // showNextSteps,
    // bookingData,
    nextStepsSubmit,
    handlePaymentDone,
    backToReview,
    bookTicket,
    bookTicketPassword,
    resendOTP,
    showCaptcha,
    handleCaptchaSubmit,
    // paymentData,
    // bookingData
  } = useBookingFlow();

  const bookingData = useGetReduxState().passengerform.bookingData;
  const showNextSteps = useGetReduxState().passengerform.showNextSteps;

  const showBookingSteps = useGetReduxState().passengerform.showBookingSteps;

  const currentBookingStep =
    useGetReduxState().passengerform.currentBookingStep;
  const otpView = useGetReduxState().passengerform.otpView;

  const { passengers, journeyPreferences, additionalDetails, gstDetails } =
    useGetReduxState().passengerform;

  useEffect(() => {
    if (
      currentBookingStep === 0 &&
      document.activeElement !== document.getElementById("disha-email-input")
    )
      document.getElementById("pass-step")?.focus();
  }, [
    currentPassengerFormStep,
    passengers,
    journeyPreferences,
    additionalDetails,
    gstDetails,
    currentBookingStep,
  ]);

  if (currentPassengerFormStep === 1) return <React.Fragment></React.Fragment>;

  if (currentPassengerFormStep === 2)
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          outline: "none",
        }}
        tabIndex={0}
        onKeyDown={(e) => {
          if (e.key === "Enter" && currentBookingStep === 0)
            handleSubmitPassengers();
        }}
        id="pass-step"
      >
        <button
          style={{
            width: "50%",
            border: "none",
            backgroundColor: "white",
            color: "black",
            fontSize: "20px",
            padding: "12px 0px",
            fontWeight: 500,
            boxShadow: "rgba(231, 231, 231, 0.75) 0px -4px 3px",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={handlePassengerFormStepBack}
        >
          {langText[lang].back}
        </button>
        <button
          style={{
            width: "50%",
            border: "1px solid rgb(50, 106, 187)",
            backgroundColor: "rgb(50, 106, 187)",
            color: "white",
            fontSize: "20px",
            padding: "12px 0px",
            //   borderRadius: "5px",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={handleSubmitPassengers}
        >
          {langText[lang].continue}
        </button>
        {error !== "" && (
          <Alert
            error={error}
            suberror={subError}
            handleClose={handleErrorClose}
          />
        )}
      </div>
    );

  if (currentPassengerFormStep > 2)
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          outline: "none",
        }}
        tabIndex={0}
        onKeyDown={(e) => {
          if (e.key === "Enter" && currentBookingStep === 0)
            handleSubmitPreferences();
        }}
        id="pass-step"
      >
        <button
          style={{
            width: "50%",
            border: "none",
            backgroundColor: "white",
            color: "black",
            fontSize: "20px",
            padding: "12px 0px",
            fontWeight: 500,
            boxShadow: "rgba(231, 231, 231, 0.75) 0px -4px 3px",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={handlePassengerFormStepBack}
        >
          {langText[lang].back}
        </button>
        <button
          style={{
            width: "50%",
            border: "1px solid rgb(50, 106, 187)",
            backgroundColor: "rgb(50, 106, 187)",
            color: "white",
            fontSize: "20px",
            padding: loading ? "9px 0px" : "12px 0px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={handleSubmitPreferences}
        >
          {loading ? (
            <Spinner
              borderWidth={"3px"}
              activeColor={"#f9f9f9"}
              inactiveColor={"#6495ed"}
              speed={"0.8s"}
              size={"25px"}
            />
          ) : (
            <span>{langText[lang].continue}</span>
          )}
        </button>
        {error !== "" && (
          <Alert
            error={error}
            suberror={subError}
            handleClose={handleErrorClose}
          />
        )}

        {showReview && reviewData.train.trainName && (
          <ReviewJourney
            data={reviewData}
            handleClose={handleCloseReview}
            submit={submitReview}
            loading={loading}
          />
        )}
        {/* otp name */}
        {otpView && (
          <OtpVerify
            backToReview={backToReview}
            loading={loading}
            sendOTP={submitReview}
          />
        )}

        {showNextSteps && bookingData.renderTemplate.data.txnToken && (
          <NextSteps handleClose={nextStepsSubmit} />
        )}

        {showCaptcha && <Captcha handleClose={handleCaptchaSubmit} />}

        {showBookingSteps && (
          <BookingSteps
            data={bookingData.renderTemplate.data}
            goBack={backToReview}
            currentStep={currentBookingStep}
            handlePaymentDone={handlePaymentDone}
            resendOTP={resendOTP}
            bookTicket={bookTicket}
            loading={loading}
            bookTicketPassword={bookTicketPassword}
          />
        )}
      </div>
    );
};

export default PassengerFormButtons;
