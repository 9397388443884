import { useEffect, useState } from "react";
import langText from "./translations";
import { Rating } from "react-simple-star-rating";
import { bookingFeedBack } from "../../Api/feedback";
import "./styles.scss";
import { CloseDrawer } from "../../Helpers/EventEmitter";
import styles from "../../Templates/PassengerForm/Steps/Passengers/AddPassenger/components/ListBox.tsx/ListBox.module.css";

const disha =
  "https://cdn.jsdelivr.net/gh/corover/assets@18June/askdisha-bucket/disha.svg";

const optionsData = {
  en: [
    { text: "Look and Feel", id: "1" },
    {
      text: "Captcha",
      id: "2",
    },
    {
      text: "Login",
      id: "3",
    },
    {
      text: "Otp",
      id: "4",
    },
    { text: "Payment Issue", id: "5" },
    {
      text: "Registration",
      id: "6",
    },
    {
      text: "Others",
      id: "7",
    },
  ],
  hi: [
    { text: "लुक और फ़ील", id: "1" },
    {
      text: "कैप्चा",
      id: "2",
    },
    {
      text: "लॉगिन",
      id: "3",
    },
    {
      text: "ओटीपी",
      id: "4",
    },
    { text: "भुगतान समस्या", id: "5" },
    {
      text: "पंजीकरण",
      id: "6",
    },
    {
      text: "अन्य",
      id: "7",
    },
  ],
  gu: [
    { text: "લૂક અને મહસૂસ", id: "1" },
    {
      text: "કેપ્ચા",
      id: "2",
    },
    {
      text: "લોગિન",
      id: "3",
    },
    {
      text: "ઓટીપી",
      id: "4",
    },
    { text: "ચૂકવણી સમસ્યા", id: "5" },
    {
      text: "નોંધણી",
      id: "6",
    },
    {
      text: "અન્ય",
      id: "7",
    },
  ],
};
const Content = () => {
  const lang = "en";

  const feedDone = false;
  const [rating, setRating] = useState(0);
  const showFeedBack = true;
  const [loading, setLoading] = useState(false);
  const [comment, setComment] = useState("");
  const [commentErr, setCommentErr] = useState("");
  const [value, setValue] = useState("");
  const [dropdown, setDropdown] = useState(false);
  const [countryList, setCountryList] = useState(optionsData[lang]);
  const [othersErr, setOthersErr] = useState("");
  const [others_, setOthers_] = useState("");
  const [selectedId, setSelectedId] = useState("");
  const handleRating = (rate: number) => {
    setRating(rate);
  };

  const [error, setError] = useState("");

  const handleSubmit = () => {
    // if (commentErr !== "" || nameErr !== "" || emailErr !== "") return false;
    // if (comment === "" || name === "" || email === "") {
    //   if (comment === "") {
    //     setCommentErr(langText[lang].errors.feedback);
    //   }
    //   if (name === "") {
    //     setNameErr(langText[lang].errors.name);
    //   }
    //   if (email === "") {
    //     setEmailErr(langText[lang].errors.email);
    //   }
    //   return;
    // }
    if (others_ === "") {
      setOthersErr(langText[lang].errors.name);
    }

    if (rating <= 4 && comment.length < 30) {
      setError(
        lang === "en"
          ? "Please enter a minimum of 30 characters"
          : "कृपया कम से कम 30 वर्ण दर्ज करें"
      );
      return;
    }

    setLoading(true);

    bookingFeedBack({ rating: rating, feedback: comment, pnr: "", txn: "" });
    CloseDrawer();
  };

  const handleCommentChange = (e) => {
    setCommentErr("");
    let value = e.target.value;
    setComment(value);
  };

  const translations = {
    hi: [
      "निराश",
      "असंतुष्ट",
      "प्रसन्न",
      "उपयोगकर्ता-मित्र",
      "उत्कृष्ट",
      "श्रेष्ठ",
      "अद्भुत",
      "असाधारण",
      "अत्यंत उत्कृष्ट",
      "अत्यधिक",
    ],
    gu: [
      "નિરાશ",
      "અસંતુષ્ટ",
      "આનંદિત",
      "વપરાશકર્તામિત્ર",
      "ઉત્કૃષ્ટ",
      "શ્રેષ્ઠ",
      "ફાંક્તાસ્ટિક",
      "અસાધારણ",
      "અત્યંત ચમત્કારી",
      "બહુ ઉત્કૃષ્ટ",
    ],
    en: [
      "Disappointed",
      "Dissatisfied",
      "Delighted",
      "User-Friendly",
      "Excellent ",
      "Superior",
      "Fantastic",
      "Exceptional",
      "Phenomenal",
      "Outstanding",
    ],
  };

  const handleSearch = (e) => {
    const inputValue = e.target.value.toLowerCase();
    setValue(inputValue);

    const filteredOptions = optionsData[lang].filter((option) =>
      option.text.toLowerCase().startsWith(inputValue)
    );

    setCountryList(filteredOptions);
  };

  const handleOthersOptions = (e) => {
    setOthersErr("");
    let value = e.target.value;
    setOthers_(value);
  };

  const onSearch = () => {
    setDropdown(true);
    setValue("");
    setSelectedId("");
  };
  const handleSelect = (data) => {
    setDropdown(false);

    setSelectedId(data.id);
    setValue(data.text);
  };
  useEffect(() => {
    setError("");
  }, [comment]);

  return (
    <div style={{ padding: "15px" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "10px",
        }}
      >
        <img style={{ width: "60px" }} src={disha} alt="" />
        <div>
          <p
            style={{
              margin: "0",
              fontSize: "26px",
              fontWeight: "700",
              color: "#4e4e4e",
              letterSpacing: "1px",
              textAlign: "center",
            }}
          >
            {langText[lang].please}
          </p>
          <div
            style={{
              fontSize: "12px",
              lineHeight: "1.2",

              textAlign: "center",
              color: "dimgray",
              marginTop: "4px",
            }}
          >
            {langText[lang].learning}
          </div>
        </div>
      </div>

      <div
        style={{
          width: "100%",
          borderTop: "1px solid gainsboro",
          margin: "10px 0px",
        }}
      ></div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          // marginTop: "15px",
        }}
      >
        {feedDone && (
          <div
            style={{
              height: "260px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <p
              style={{
                margin: "0",
                fontSize: "24px",
                color: "darkslategray",
              }}
            >
              {langText[lang].thanks}
            </p>
          </div>
        )}

        {!feedDone && (
          <>
            <p
              style={{
                margin: "0",
                fontSize: "16px",
                color: "darkslategray",
                marginBottom: "10px",
              }}
            >
              {langText[lang].rate}
            </p>
            <Rating
              onClick={handleRating}
              ratingValue={rating}
              size={32}
              style={{
                marginLeft: "22%",
              }}
              fillColor={"#ffda0b"}
              allowHalfIcon={true}
              transition={true}
              tooltipArray={translations[lang]}
              showTooltip
              tooltipStyle={{
                backgroundColor: "white",
                position: "fixed",
                fontSize: "16px",
                color: "rgb(52, 109, 179)",
                border: "1px solid rgb(52, 109, 179)",
                padding: "5px",
              }}
            />

            {showFeedBack && (
              <>
                <div
                  style={{
                    width: "100%",
                    border: "1px solid #b2b2b2",
                    padding: "14px",
                    borderRadius: "5px",
                    marginTop: "10px",
                  }}
                >
                  <div className="input2">
                    <input
                      type="text"
                      placeholder={langText[lang].placeholders.email}
                      style={{ marginBottom: "5px" }}
                      value={value}
                      // onChange={handleSearch}
                      onClick={onSearch}
                    />
                  </div>
                  {selectedId === "7" && (
                    <>
                      <div className="input1">
                        <input
                          type="text"
                          placeholder={langText[lang].placeholders.name}
                          style={{ marginBottom: "5px" }}
                          // value={value}
                          onChange={handleOthersOptions}
                          // onClick={onSearch}
                        />
                        {othersErr !== "" && (
                          <p
                            style={{
                              margin: 0,
                              color: "tomato",
                              fontSize: "14px",
                            }}
                          >
                            {othersErr}
                          </p>
                        )}
                      </div>
                    </>
                  )}

                  {dropdown && (
                    <div
                      style={{
                        backgroundColor: "white",
                        // height: "",
                        overflowY: "auto",
                        display: "flex",
                        paddingTop: "5px",
                        flexFlow: "wrap",
                        alignItems: "flex-end",
                        justifyContent: "flex-end",
                        // position: "absolute",
                        width: "100%",
                        bottom: "19px",
                      }}
                      className={styles.container}
                    >
                      {countryList.map((country) => (
                        <div
                          className={styles.country}
                          onClick={() => handleSelect(country)}
                        >
                          {country.text}
                        </div>
                      ))}
                    </div>
                  )}
                  {value !== "" && (
                    <>
                      <div className="input1">
                        {/* <p>Your Suggestions and Feedback</p> */}
                        <textarea
                          name=""
                          cols={30}
                          placeholder={langText[lang].placeholders.feedback}
                          rows={3}
                          onChange={handleCommentChange}
                          style={{
                            borderRadius: "5px",
                            fontSize: "16px",
                            color: "black",
                          }}
                        ></textarea>
                        {commentErr !== "" && (
                          <p
                            style={{
                              margin: 0,
                              color: "tomato",
                              fontSize: "14px",
                            }}
                          >
                            {commentErr}
                          </p>
                        )}
                        {error && (
                          <p
                            style={{
                              color: "tomato",
                              textAlign: "left",
                              fontSize: "14px",
                            }}
                          >
                            {error}
                          </p>
                        )}
                      </div>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "end",
                          alignItems: "center",
                        }}
                      >
                        <button
                          className="feed-btn"
                          disabled={loading || error !== ""}
                          style={{}}
                          onClick={handleSubmit}
                        >
                          {langText[lang].submit}
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Content;
