import axios from "axios";
import { getChannel, getDSession } from "../Helpers/misc";
import store from "../Store";
import getHeaders from "./getHeaders";
import { v4 as uuid4 } from "uuid";

// import DbStorage from "../../services/dbService";
let cancelToken;

export const checkDate = async (value, language) => {
  if (cancelToken) {
    cancelToken.cancel("Operation cancelled due to new request");
  }
  cancelToken = axios.CancelToken.source();
  try {
    const response = await axios.post(
      `/dishaAPI/bot/checkDate/${language}`,
      {
        date: value,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (e) {
    return -1;
  }
};

export const getFAQs = async (langCode) => {
  try {
    const response = await axios.get(`/dishaAPI/bot/questions/${langCode}`, {
      headers: {
        "app-id": "29fd4f94-f793-4227-9588-056b5ffb1318",
        "auth-Key": "2b5fb5d4-0753-4302-b661-f8580e9effb0",
        "Content-Type": "application/json",
      },
      data: {},
    });

    return response.data.filter((element) => element !== "");
  } catch (error) {
    console.log(error.message);
  }
};

export const getUpcomingBookings = async () => {
  if (cancelToken) {
    cancelToken.cancel("Operation cancelled due to new request");
  }
  cancelToken = axios.CancelToken.source();

  const configuration = store.getState().app;
  const inputMode = store.getState().behaviour.input_mode;
  let payload = {
    query: "Booking History",
    source: window.navigator.userAgent,
    inputType: inputMode,
    next_context: "",
    cxpayload: null,
    userToken: configuration.userToken || null,
    suggestion: false,
    isFallback: null,
    isRefund: null,
    channel: getChannel(),
    prevCode: null,
    audioUrl: null,
    dSession: getDSession(),
    deviceId: configuration.deviceId,
    sessionId: configuration.sessionId,
    status: 1,
  };

  try {
    const response = await axios.post(
      `/dishaAPI/bot/sendQuery/en`,

      payload,
      {
        headers: getHeaders(),
        cancelToken: cancelToken.token,
      }
    );
    if (response.data.renderTemplate.data) {
      return response.data.renderTemplate.data.upcomingjourney;
    } else return [];
  } catch (e) {
    return [];
  }
};

export const getUserData = async () => {
  try {
    const response = await axios.get(`/dishaAPI/bot/getUserData`, {
      headers: getHeaders(),
    });

    return response.data;
  } catch (error) {
    console.log(error.message);
    return null;
  }
};

export const getSettings = async () => {
  try {
    const response = await axios.get(`/dishaAPI/bot/getSettings`);

    return {
      isDisabled: response.data.isDisabled,
      booking: response.data.booking,
    };
  } catch (err) {
    return {
      isDisabled: false,
      booking: true,
    };
  }
};

// const getSpeechURL = async (blob) => {
//   let formData = new FormData();
//   formData.append("queryAudio", blob);
//   let lang = "en";
//   //     let lang = DbStorage.getInstance().getLang();

//   return axios
//     .post("/cognitive/speech?langCode=" + lang, formData)
//     .then((response) => {
//       return response.data;
//     });
// };

export const clearTempFlow = async () => {
  try {
    await axios.get(`/dishaAPI/bot/clearTempFlow`, {
      headers: getHeaders(),
    });
  } catch (err) {}
};

export const getPendingBookings = async () => {
  const configuration = store.getState().app;
  const payload = {
    channel: getChannel(),
    userToken: configuration.userToken,
  };

  try {
    const response = await axios.post(`/dishaAPI/bot/showBooking`, payload, {
      headers: getHeaders(),
    });

    let resp = response.data;
    if (resp.pending && resp.pending[0])
      return {
        isPending: true,
        data: resp.pending[0],
      };
    else
      return {
        isPending: false,
        data: null,
      };
  } catch (error) {
    return {
      isPending: false,
      data: null,
    };
  }
};

export const savePassengerList = async (passengers) => {
  const configuration = store.getState().app;

  const payload = {
    userToken: configuration.userToken,
    data: passengers,
  };

  try {
    await axios.post(`/dishaAPI/bot/updateMasterData`, payload, {
      headers: getHeaders(),
    });

    return true;
  } catch (error) {
    return false;
  }
};

export const getCountries = async () => {
  try {
    const response = await axios.get(
      "https://storage.corover.ai/askdisha-irctc/countries.json",
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return response.data;
  } catch {
    return [];
  }
};

export const addCounter = async () => {
  let uid = uuid4();
  try {
    const response: any = await axios.post(`/dishaAPI/bot/addCounter`, {
      channel: window.location.search ? window.location.href : getChannel(),
      source: navigator.userAgent,
    });

    return response.data.session ? response.data.session : uid;
  } catch (err) {
    return uid;
  }
};

export const checkSuccess = async () => {
  try {
    const response: any = await axios.get(`/dishaAPI/bot/checkSuccess`, {
      headers: getHeaders(),
    });

    return response.data;
  } catch (err) {
    return {
      error: "Failed to check success!",
    };
  }
};

export const callPassword = async () => {
  let payload = {
    userToken: store.getState().app.userToken,
    channel: getChannel(),
  };
  try {
    const response: any = await axios.post(
      `/dishaAPI/bot/callPassword`,
      payload,
      { headers: getHeaders() }
    );

    return response.data;
  } catch (err) {
    return {
      error: "Failed to call password!",
    };
  }
};

export const getRefundOfFailed = async (orderId) => {
  try {
    const response: any = await axios.get(
      `/dishaAPI/bot/getRefundStatusByTxn/${orderId}`,
      {
        headers: getHeaders(),
      }
    );

    return response.data;
  } catch (err) {
    return {
      error: "Failed to check success!",
    };
  }
};
