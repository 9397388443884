import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import useGetReduxState from "../../../../Hooks/useGetReduxState";
import AddPassenger from "./AddPassenger/AddPassenger";
import Passenger from "./Passenger2";
import Container from "./styles";
import langText from "./translations";

const noPassengers =
  "https://cdn.jsdelivr.net/gh/corover/assets@latest/askdisha-bucket/traveler-grey.png";
const Passengers = () => {
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const passengerData = useGetReduxState().passengerform;
  const handleAddClick = () => {
    setOpenAddDialog(true);
  };
  const lang: "hi" | "en" | "gu" = useGetReduxState().behaviour.lang;
  return (
    <Container>
      <div
        style={{
          width: "100%",
          padding: "15px",
          minHeight: "220px",
          maxHeight: "270px",
          overflow: "auto",
          position: "relative",
        }}
      >
        {passengerData.passengers.length < 1 && (
          <div className="no-passengers">
            <img src={noPassengers} alt="" />
            <p>{langText[lang].no}</p>
          </div>
        )}
        {passengerData.passengers.map((passenger) => {
          return <Passenger passenger={passenger} />;
        })}
      </div>

      <button onClick={handleAddClick}>
        <FontAwesomeIcon icon={faPlus} style={{ marginRight: "5px" }} />{" "}
        {langText[lang].addButton}
      </button>
      {openAddDialog && (
        <AddPassenger
          passengerProp={null}
          isInMasterData={false}
          handleCloseDrawer={() => setOpenAddDialog(false)}
          isAlloted={false}
        />
      )}
      <p
      style={{
        marginTop: "8px",
        fontWeight:"600",
        fontSize: "10px",
        textAlign: "left",
        marginLeft: "12px",
        }}
        >*Children under 5 years of age shall be carried free and no purchase of any ticket is required. (If no separate berth is opted.)</p>

    </Container>
  );
};

export default Passengers;
