import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import text from "./translations";
import useGetReduxState from "../../../../../../../Hooks/useGetReduxState";
const Expand = () => {
  const lang = useGetReduxState().behaviour.lang;

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "2px 10px",
          marginBottom: "15px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <div
            style={{
              justifyContent: "space-between",
              maxHeight: "1500px",
              alignItems: "center",
            }}
          >
            <p
              style={{
                // margin: "0",
                textAlign: "justify",
                margin: "14px 5px 0px 5px",
                fontSize: "14px",
                color: "gray",
                fontWeight: "600",
                lineHeight: "150%",
              }}
            >
              {text[lang].first}
            </p>
            <p
              style={{
                // margin: "0",
                margin: "14px 5px 0px 5px",
                fontSize: "14px",
                color: "gray",
                fontWeight: "600",
                lineHeight: "150%",
                textAlign: "justify",
              }}
            >
              {text[lang].second}
            </p>
            <p
              style={{
                // margin: "0",
                textAlign: "justify",
                margin: "14px 5px 0px 5px",
                fontSize: "14px",
                color: "gray",
                fontWeight: "600",
                lineHeight: "150%",
              }}
            >
              {text[lang].third}
            </p>

            <h3
              style={{
                margin: "0",
                marginTop: "14px",
                fontSize: "14px",
                color: "Black",
                fontWeight: "700",
                lineHeight: "150%",
              }}
            >
              {text[lang].subone}
            </h3>
            <div style={{ paddingLeft: "15px" }}>
              <ul
                style={{
                  margin: "0",
                  marginTop: "14px",
                  fontSize: "14px",
                  color: "gray",
                  fontWeight: "600",
                  lineHeight: "150%",
                }}
              >
                <li
                  style={{
                    lineHeight: "150%",
                  }}
                >
                  {text[lang].l11}
                </li>
                <li
                  style={{
                    lineHeight: "150%",
                  }}
                >
                  {text[lang].l12}
                </li>
                <li
                  style={{
                    lineHeight: "150%",
                  }}
                >
                  {text[lang].l13}
                </li>
                <li
                  style={{
                    lineHeight: "150%",
                  }}
                >
                  {text[lang].l14}
                </li>
                <li
                  style={{
                    lineHeight: "150%",
                  }}
                >
                  {text[lang].l15}
                </li>
                <li
                  style={{
                    lineHeight: "150%",
                  }}
                >
                  {text[lang].l16}
                </li>
              </ul>
            </div>
            <h3
              style={{
                margin: "0",
                marginTop: "14px",
                fontSize: "14px",
                color: "Black",
                fontWeight: "700",
                lineHeight: "150%",
              }}
            >
              {text[lang].subtwo}
            </h3>
            <div style={{ paddingLeft: "15px" }}>
              <ul
                style={{
                  margin: "0",
                  marginTop: "14px",
                  fontSize: "14px",
                  color: "gray",
                  fontWeight: "600",
                  lineHeight: "150%",
                }}
              >
                <li
                  style={{
                    lineHeight: "150%",
                  }}
                >
                  {text[lang].l21}
                </li>
                <li
                  style={{
                    lineHeight: "150%",
                  }}
                >
                  {text[lang].l22}
                </li>
                <li
                  style={{
                    lineHeight: "150%",
                  }}
                >
                  {text[lang].l23}
                </li>
                <li
                  style={{
                    lineHeight: "150%",
                  }}
                >
                  {text[lang].l24}
                </li>
                <li
                  style={{
                    lineHeight: "150%",
                  }}
                >
                  {text[lang].l25}
                </li>
                <li
                  style={{
                    lineHeight: "150%",
                  }}
                >
                  {text[lang].l26}
                </li>
                <li
                  style={{
                    lineHeight: "150%",
                  }}
                >
                  {text[lang].l27}
                </li>
                <li
                  style={{
                    lineHeight: "150%",
                  }}
                >
                  {text[lang].l28}
                </li>
                <li
                  style={{
                    lineHeight: "150%",
                  }}
                >
                  {text[lang].l29}
                </li>
                {text[lang].l20}
              </ul>
            </div>
          </div>
        </div>
        {/* <FontAwesomeIcon
            icon={faAngleRight}
            style={{
              border: "1px solid #f44627",
              color: "#f44627",
              padding: "3px 7px",
              borderRadius: "50%",
              marginRight: "9px",
            }}
          /> */}
      </div>
    </>
  );
};

const About = () => {
  return (
    <div
      style={{
        marginTop: "0px",
        overflow: "hidden",
        transition: "all 0.5s",
      }}
    >
      <Expand />
    </div>
  );
};

export default About;
