import { useEffect, useState } from "react";
import { sendUiErrorReport } from "../../Api/feedback";
import useGetReduxState from "../../Hooks/useGetReduxState";
import langText from "./translations";
const disha =
  "https://cdn.jsdelivr.net/gh/corover/assets@latest/askdisha-bucket/Disha-400.svg";

export default function ErrorFallback({ error, resetErrorBoundary }) {
  const lang = useGetReduxState().behaviour.lang;

  const [clicked, setClicked] = useState(false);

  useEffect(() => {
    sendUiErrorReport(error.toString());
  }, []);

  return (
    <div style={{ width: "100%", height: "calc(100% - 12px)" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
          position: "absolute",
          top: "50%",
          transform: "translateY(-50%)",
        }}
      >
        <img style={{ width: "80px" }} src={disha} alt="" />
        <p
          style={{
            marginTop: "10px",
            fontSize: "22px",
            fontWeight: 500,
            color: "#ff2c2e",
          }}
        >
          {langText[lang].header}
        </p>
        <p style={{ marginTop: "5px", color: "gray" }}>
          {langText[lang].subtext}
        </p>
        {!clicked ? (
          <>
            {" "}
            <p style={{ margin: "0", color: "gray", marginTop: "18px" }}>
              {langText[lang].send}
            </p>
            <button
              style={{
                backgroundColor: "#326abb",
                color: "white",
                border: "1px solid #326abb",
                fontSize: "16px",
                padding: "10px 35px",
                borderRadius: "5px",
                marginBottom: "10px",
                marginTop: "10px",
                cursor: "pointer",
              }}
              onClick={() => {
                setClicked(true);
              }}
            >
              {langText[lang].button1}
            </button>{" "}
          </>
        ) : (
          <>
            <p
              style={{
                color: "#326abb",
                fontWeight: 600,
                fontSize: "20px",
                margin: "10px 0px",
              }}
            >
              {langText[lang].sent}
            </p>
          </>
        )}

        <button
          style={{
            backgroundColor: "white",
            color: "#326abb",
            border: "1px solid #326abb",
            fontSize: "16px",
            padding: "10px 35px",
            borderRadius: "5px",
            marginBottom: "10px",
            cursor: "pointer",
          }}
          onClick={resetErrorBoundary}
        >
          {langText[lang].button2}
        </button>
      </div>
    </div>
  );
}
