import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { useEffect } from "react";
import useGetReduxState from "../../../../../Hooks/useGetReduxState";

import BookingDetailsContainer from "./BookingDetails.styles";
import langText from "./translations";

const ContentForFailed = ({ data }) => {
  useEffect(() => {}, []);
  const lang: "en" | "gu" | "hi" = useGetReduxState().behaviour.lang;
  return (
    <BookingDetailsContainer refunded={data.status === "DONE"}>
      <div className="first">
        <div className="headers">
          <p>{langText[lang].orderId}</p>
          <p>{langText[lang].txnId}</p>
          <p>{langText[lang].dateTxn}</p>
          <p>{langText[lang].timeTxn}</p>
        </div>
        <div className="values">
          <p>{data.orderId}</p>
          <p>{data.txnId}</p>
          <p>{moment(data.date).format("DD MMMM, YYYY")}</p>
          <p>{moment(data.date).format("hh:mm A")}</p>
        </div>
      </div>

      <div className="refund-status">
        <p>{langText[lang].refund}</p>
        {data.status === "DONE" ? (
          <span>{langText[lang].initiated}</span>
        ) : (
          <span>{langText[lang].pending}</span>
        )}
      </div>
      <div className="message">
        <FontAwesomeIcon className="icon" icon={faCircleExclamation} />
        {data.status === "DONE" ? (
          <p>{langText[lang].initiatedMsg}</p>
        ) : (
          <p>{langText[lang].pendingMsg}</p>
        )}
      </div>
      <div className="seperator"></div>

      {/* <div className="seperator"></div> */}
      <div className="amount-details">
        <div>
          <p>{langText[lang].txnAmount} - </p>
          <span className="paid">₹{data.txnAmount}</span>
        </div>
        <div>
          <p>{langText[lang].refundAmount} - </p>
          <span className="refund">
            ₹{data.refundAmount} + {langText[lang].pg}
          </span>
        </div>
      </div>
      <div className="seperator"></div>
      <div className="payment-details">
        <div>
          <p>{langText[lang].txnMode} - </p>
          <span>{data.paymentMode}</span>
        </div>
        <div>
          <p>{langText[lang].banktxnId} - </p>
          <span>{data.bankTxnId}</span>
        </div>
        <div>
          <p>{langText[lang].bankName} - </p>
          <span>{data.bankName}</span>
        </div>
      </div>
    </BookingDetailsContainer>
  );
};

export default ContentForFailed;
