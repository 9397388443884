import useGetReduxState from "../../../Hooks/useGetReduxState";
import langText from "./translations";

const noTrains =
  "https://cdn.jsdelivr.net/gh/corover/assets@latest/askdisha-bucket/no-route.svg";
const NotFound = ({ edit }) => {
  const lang: "hi" | "en" | "gu" = useGetReduxState().behaviour.lang;

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <img
        style={{ width: "35%", marginTop: "-30px" }}
        src={noTrains}
        alt="No Trains"
      />
      <span
        style={{
          display: "block",
          color: "rgb(93 93 93)",
          fontWeight: "500",
          fontSize: "20px",
          marginTop: "10px",
          marginBottom: "50px",
        }}
      >
        {langText[lang].noForJourney}
      </span>

      <button
        style={{
          background: "#326abb",
          color: "white",
          border: "none",
          padding: "12px",
          fontSize: "16px",
          borderRadius: "8px",
          width: "50%",
          cursor: "pointer",
        }}
        onClick={edit}
      >
        {langText[lang].try}
      </button>
    </div>
  );
};

export default NotFound;
