import React, { useState, useEffect } from "react";
import { getStationName } from "../../../Helpers/getStationName";
import Container from "./styles";
import ScrollContainer from "react-indiana-drag-scroll";
import { getQuotaName } from "../../../Helpers/getQuotaName";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import Spinner from "../../../UI/UIComponents/Spinner";
import NotAllowed from "../../NotAllowed";

const disha =
  "https://cdn.jsdelivr.net/gh/corover/assets@latest/askdisha-bucket/Disha-400.svg";

const Content = ({
  train,
  getClassFiltered,
  quotas,
  selectedQuota,
  setSelectedQuota,
  tickets,
  selectedTicket,
  setSelectedTicket,
  loading,
  error,
  availabilities,
  handleDateChange,
  selectedDay,
  getColor,
  showAvailabilityPopUp,
  setShowAvailabilityPopUp,
}) => {

  
  // const [t, st] = useState(false);

  // useEffect(() => {
  //   if (showAvailabilityPopUp == "") {
  //     st(true);
  //   } else {
  //     setShowAvailabilityPopUp("1");
  //     st(false);
  //   }
  // }, [showAvailabilityPopUp]);


  return (
    <Container>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          marginBottom: "25px",
          marginTop: "10px",
        }}
      >
        <p style={{ margin: "0", color: "#080808", fontWeight: "600" }}>
          {train.trainName}
        </p>
        <p
          style={{
            margin: "0",
            marginLeft: "10px",
            fontSize: "12px",
            fontWeight: "500",
          }}
        >
          {train.trainNumber}
        </p>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <p
          style={{
            margin: "0",
            color: "#080808",
            fontSize: "12px",
            fontWeight: "600",
          }}
        >
          {train.departureTime}
        </p>
        <div
          style={{ width: "100%", position: "relative", margin: "0px 20px" }}
        >
          <div
            style={{
              position: "absolute",
              left: "50%",
              transform: "translateX(-50%)",
              top: "-15px",
            }}
          >
            <p
              style={{
                margin: "0px",
                fontSize: "10px",
                fontWeight: "500",
                color: "#080808",
              }}
            >
              {train.duration.split(":")[0]}hrs {train.duration.split(":")[1]}
              min
            </p>
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <span
              style={{
                display: "block",
                width: "6px",
                height: "6px",
                borderRadius: "50%",
                border: "1px solid #909090",
              }}
            ></span>
            <div
              style={{ borderTop: "1px solid #cbcbcb", width: "100%" }}
            ></div>
            <span
              style={{
                display: "block",
                width: "6px",
                height: "6px",
                borderRadius: "50%",
                border: "1px solid #909090",
              }}
            ></span>
          </div>
        </div>
        <p
          style={{
            margin: "0",
            color: "#080808",
            fontSize: "12px",
            fontWeight: "600",
          }}
        >
          {train.arrivalTime}
        </p>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          fontSize: "12px",
          fontWeight: "600",
          marginTop: "10px",
        }}
      >
        <p>
          {getStationName(train.source)} ({train.source})
        </p>
        <p>
          {getStationName(train.destination)} ({train.destination})
        </p>
      </div>
      <div
        style={{
          width: "100%",
          borderTop: "5px solid #F6F6F6",
          marginBottom: "10px",
          marginTop: "18px",
        }}
      ></div>
      <ScrollContainer
        className="quotas"
        style={{
          whiteSpace: "nowrap",
          marginBottom: "10px",
          marginTop: "20px",
          width: "96%",
          marginLeft: "auto",
          marginRight: "auto",
          fontSize: "12px",
        }}
      >
        {quotas.map((quota) => (
          <span
            className={
              selectedQuota === quota ? "selected-quota" : "quota-expanded"
            }
            onClick={() => {
              setSelectedQuota(quota);
            }}
          >
            {getQuotaName(quota)}
          </span>
        ))}
      </ScrollContainer>

      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          columnGap: "22px",
          padding: "2px 0px",
          marginLeft: "10px",
          marginTop: "15px",
        }}
      >
        {getClassFiltered(tickets)
          .slice()
          .reverse()
          .map((ticket) => (
            <div
              style={{
                fontSize: "18px",
                fontWeight: "600",
                position: "relative",
                // border:
                //   selectedTicket === ticket.class
                //     ? "1px solid #009ffd"
                //     : "1px solid dimgrey",
                padding: "0px 7px",
                borderRadius: "4px",
                color: selectedTicket === ticket.class ? "#326ABB" : "black",
                cursor: "pointer",
                // backgroundColor:
                //   selectedTicket === ticket.class ? "#009ffd" : "white",
              }}
              onClick={() => {
                // props.click(props.train, ticket)
                setSelectedTicket(ticket.class);
              }}
            >
              {ticket.class}

              {selectedTicket === ticket.class && (
                <span
                  style={{
                    position: "absolute",
                    width: "120%",
                    height: "4px",
                    backgroundColor: "#326ABB",
                    borderRadius: "10px",
                    left: "50%",
                    bottom: "-12px",
                    transform: "translateX(-50%)",
                  }}
                ></span>
              )}
            </div>
          ))}
      </div>
      <div
        style={{
          width: "100%",
          borderTop: "1px solid gainsboro",
          marginTop: "8px",
        }}
      ></div>

      <div
        className="train-days"
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          marginTop: "10px",
          marginBottom: "15px",
        }}
      >
        {error !== "" && !loading && (
          <div style={{ marginTop: "35px" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                style={{
                  color: "tomato",
                  fontSize: "50px",
                }}
              />
              <img
                src={disha}
                alt=""
                style={{ width: "42px", marginLeft: "10px" }}
              />
            </div>

            <p
              style={{
                margin: "12px 0px 0px",
                fontSize: "14px",
                color: "tomato",
                textAlign: "center",
                lineHeight: "20px",
              }}
            >
              {error}
            </p>
          </div>
        )}
        {loading && (
          <div className="loader">
            <Spinner
              borderWidth={"5px"}
              activeColor={"#6495ed"}
              inactiveColor={"#f9f9f9"}
              speed={"1s"}
              size={"40px"}
            />
          </div>
        )}
        {!loading &&
          availabilities.map((day) => (
            <div
              className="day"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "10px 5px",
                borderBottom: "1px solid gainsboro",
                cursor: "pointer",
              }}
              onClick={() => {
                handleDateChange(day);
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    width: "18px",
                    height: "18px",
                    border: "1px solid #505050",
                    borderRadius: "50%",
                    marginRight: "15px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  <span
                    style={{
                      width: "12px",
                      height: "12px",
                      backgroundColor:
                        selectedDay === day.availablityDate
                          ? "#326ABB"
                          : "white",
                      display: "block",
                      borderRadius: "50%",
                    }}
                  ></span>
                </div>
                <p
                  style={{
                    margin: "0",
                    fontSize: "14px",
                    fontWeight: "600",
                    color: "#535353",
                  }}
                >
                  {moment(day.availablityDate, "D-M-YYYY").format(
                    "ddd, DD MMM"
                  )}
                </p>
              </div>
              <p
                style={{
                  margin: "0",
                  color: getColor(day.availablityStatus),
                  fontSize: "14px",
                  fontWeight: 600,
                }}
              >
                {day.availablityStatus}
              </p>
            </div>
          ))}
      </div>
      {/* {t && (
        <NotAllowed
          For={showAvailabilityPopUp}
          date={moment().format("YYYYMMDD")}
          handleClose={() => st(false)}
        />
      )} */}
    </Container>
  );
};



export default Content;
