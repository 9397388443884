import axios from "axios";
import store from "../Store";
import getHeaders from "./getHeaders";
import { getDSession } from "../Helpers/misc";
import { useState } from "react";

const translations = {
  en: {
    invalid: "Invalid Id! Please provide your correct IRCTC User ID!",
    unverified:
      "Sorry, this ID is not verified! Please verify your User ID first!",
    disabled:
      "Sorry, this User ID is disabled. Please check on IRCTC website to continue!",
    exhausted: "Booking quota exceeded on AskDisha for given ID",
    suspend: "Sorry, booking not allowed as your account has been suspended.",
  },
  hi: {
    invalid: "अमान्य Id! कृपया अपना सही IRCTC User Id प्रदान करें!",
    unverified:
      "क्षमा करें, यह Id सत्यापित नहीं है! कृपया पहले अपना User Id सत्यापित करें!",
    disabled:
      "क्षमा करें, यह User Id अक्षम है। जारी रखने के लिए कृपया IRCTC की वेबसाइट देखें!",
    exhausted: "इस आईडी के लिए AskDisha पर बुकिंग कोटा पार कर गया है।",
    suspend:
      "क्षमा करें, बुकिंग की अनुमति नहीं है क्योंकि आपका खाता निलंबित कर दिया गया है।",
  },
  gu: {
    invalid:
      "અમાન્ય ID! મહેરબાની કરીને તમારું સાચું આઈ. આર. સી. ટી. સી. વપરાશકર્તા ઓળખપત્ર પ્રદાન કરો!",
    unverified:
      "માફ કરશો, આ ID ચકાસાયેલ નથી! મહેરબાની કરીને પહેલા તમારા વપરાશકર્તા ઓળખપત્રની ચકાસણી કરો!",
    disabled:
      "માફ કરશો, આ વપરાશકર્તા ID નિષ્ક્રિય છે. ચાલુ રાખવા માટે કૃપા કરીને આઈ. આર. સી. ટી. સી. ની વેબસાઇટ તપાસો!",
    exhausted: "આ આઈડી માટે AskDisha પર બુકિંગ ક્વોટા પાર કરાયો છે।",
    suspend:
      "માફ કરશો, તમારું એકાઉન્ટ સસ્પેન્ડ કરવામાં આવ્યું હોવાથી બુકિંગની મંજૂરી નથી.",
  },
};

export const verifyId = async (id) => {
  const lang: "hi" | "en" | "gu" = store.getState().behaviour.lang;

  try {
    const configuration = store.getState().app;
    let payload = {
      sessionId: configuration.sessionId,
      dSession: getDSession(),
    };
    const response = await axios.post(
      `/dishaAPI/bot/getUserId/${id}`,
      payload,
      {
        headers: getHeaders(),
      }
    );

    let verified = false;
    let error = "";
    let email = "";
    let mobile = "";
    let result = response.data.response;

    if (result.error) {
      verified = false;
      error = translations[lang].invalid;
    } else if (!result.status) {
      verified = false;
      error = translations[lang].invalid;
    } else {
      let status = result.status.toString();

      if (status.startsWith("0")) {
        verified = false;
        error = translations[lang].unverified;
      } else if (status.split("#")[1].startsWith("0")) {
        verified = false;
        error = translations[lang].disabled;
      } else if (status.startsWith("1") && status.charAt(2) === "3") {
        error = translations[lang].suspend;
      } else {
        verified = true;

        const details = await axios.get(
          `/dishaAPI/client/getUserBookingCount/${id}`,
          {
            headers: getHeaders(),
          }
        );

        email = details.data.email;
        mobile = details.data.mobile;
        let bookingCount = parseInt(details.data.monthlyBookingCount);
        // if (bookingCount > 11) error = translations[lang].exhausted;
        console.log("RESULT : ", bookingCount > 11);
      }
    }

    return {
      verified,
      error,
      email,
      mobile,
    };
  } catch (error) {
    return {
      verified: false,
      error: "Oops! Something went wrong! Please try again.",
      email: "",
      mobile: "",
    };
  }
};

export const sendOTPagain = async () => {
  const configuration = store.getState().app;
  try {
    let payload = {
      resend: "Y",
      userToken: configuration.userToken || null,
    };
    const response = await axios.post(`/dishaAPI/bot/sendSms`, payload, {
      headers: getHeaders(),
    });

    let data = response.data;

    if (data.error) {
      return false;
    }

    return true;
  } catch (error) {
    return false;
  }
};

export const getQRCode = async (pnr) => {
  try {
    const response = await axios.get(`/dishaAPI/addservices/eticket/${pnr}`, {
      headers: getHeaders(),
    });
    return response.data.qr;
  } catch (e) {
    return "";
  }
};

export const getBookingDetails = async (pnr) => {
  try {
    const response = await axios.get(`/dishaAPI/addservices/eticket/${pnr}`, {
      headers: getHeaders(),
    });
    return response.data;
  } catch (e) {
    return "";
  }
};
