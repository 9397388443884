import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import ReactDOM from "react-dom";
import useGetReduxState from "../../Hooks/useGetReduxState";
import langText from "./translations";
const disha =
  "https://cdn.jsdelivr.net/gh/corover/assets@latest/askdisha-bucket/Disha-400.svg";

const Fallback500 = ({ handleTry, handleHome }) => {
  const lang: "hi" | "en" | "gu" = useGetReduxState().behaviour.lang;

  return ReactDOM.createPortal(
    <div
      style={{
        position: "absolute",
        inset: 0,
        background: "#00000096",
        zIndex: 99,
      }}
    >
      <div
        style={{
          position: "absolute",
          top: "45%",
          left: "50%",
          background: "white",
          transform: "translate(-50%, -50%)",
          width: "90%",
          padding: "18px 15px",
          borderRadius: "10px",
        }}
      >
        <div style={{}}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <FontAwesomeIcon
              icon={faExclamationTriangle}
              style={{
                color: "tomato",
                fontSize: "50px",
              }}
            />
            <img
              src={disha}
              alt=""
              style={{ width: "35px", marginLeft: "-12px", marginTop: "15px" }}
            />
          </div>

          <p
            style={{
              margin: "12px 0px 0px",
              fontSize: "20px",
              color: "rgb(102, 102, 102)",
              textAlign: "center",
              lineHeight: "20px",
            }}
          >
            {langText[lang].oops}
          </p>

          <p
            style={{
              margin: "12px 0px 0px",
              fontSize: "16px",
              color: "gray",
              textAlign: "center",
              lineHeight: "20px",
            }}
          >
            {langText[lang].developers}
          </p>
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
            }}
          >
            <button
              style={{
                width: "45%",
                display: "block",
                margin: "12px auto 0px",
                fontSize: "16px",

                backgroundColor: "white",
                color: "black",
                borderRadius: "5px",
                padding: "9px",
                cursor: "pointer",
                border: "1px solid #bcbcbc",
              }}
              onClick={handleHome}
            >
              {langText[lang].home}
            </button>
            <button
              style={{
                width: "45%",
                display: "block",
                margin: "12px auto 0px",
                fontSize: "16px",
                border: "none",
                backgroundColor: "#326abb",
                color: "white",
                borderRadius: "5px",
                padding: "10px",
                cursor: "pointer",
              }}
              onClick={handleTry}
            >
              {langText[lang].again}
            </button>
          </div>
        </div>
      </div>
    </div>,
    document.getElementById("corover-body"),
  );
};

export default Fallback500;
