import { faMicrophone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Microphone } from "../../../Chatbot/Microphone";
import styles from "./TextBox.module.css";
export const TextBox = (props: any) => {
  const {
    label,
    placeholder,
    id,
    onChange,
    error,
    value,
    type,
    handleVoice,
    content,
    handleMic,
    suggestion,
    onSelect,
    style,
  } = props;
  const [clicked, setClicked] = useState(false);
  const [mic, setMic] = useState(false);
  const [scale, setScale] = useState(1);

  const handleChange = (e) => {
    if (e.target.value !== "") setClicked(true);
    else setClicked(false);

    onChange(e);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setScale((prev) => {
        if (prev > 1.2) {
          return 1;
        }
        return prev + 0.02;
      });
    }, 100);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div style={{ width: "100%" }}>
      <div
        style={{ marginTop: clicked && label ? "16px" : "10px" }}
        className={styles.textbox2}
      >
        {value !== "" && label && (
          <label style={{}} htmlFor="name">
            {label}
          </label>
        )}

        <div
          style={{
            width: "100%",
          }}
        >
          <input
            id={id}
            name={id}
            type={type}
            placeholder={placeholder}
            onChange={handleChange}
            value={value}
            style={style}
          />
          {suggestion && suggestion.length > 0 && (
            <div
              style={{
                position: "absolute",
                border: "1px solid #d4d4d4",
                borderTop: "none",
                zIndex: "99",
                top: "100%",
                left: "0",
                right: "0",
                background: "white",
                borderBottom: "1px solid #d4d4d4",
                borderRadius: "4px",
                maxHeight: "160px",
                overflowY: "scroll",
                overflowX: "hidden",
              }}
            >
              {suggestion.map((elem, index) => (
                <p
                  key={index}
                  style={{
                    padding: "8px",
                    cursor: "pointer",
                    borderBottom: "1px solid #d4d4d4",
                  }}
                  onClick={() => onSelect(elem)}
                >
                  {elem}
                </p>
              ))}
            </div>
          )}
        </div>
        <FontAwesomeIcon
          style={{
            position: "absolute",
            right: "10px",
            top: "50%",
            transform: "translateY(-50%)",
            color: `${props.animate ? "#326abb" : "#a5a4a4"}`,
            fontSize: "20px",
            cursor: "pointer",
            scale: props.animate ? scale.toString() : "1",
          }}
          icon={faMicrophone}
          onClick={() => (handleMic ? handleMic() : setMic(true))}
        />
      </div>
      {error && (
        <p
          style={{
            fontSize: "12px",
            color: "tomato",
            fontWeight: "400",
            marginTop: "5px",
            marginLeft: "4px",
          }}
        >
          {error}
        </p>
      )}
      {mic && (
        <Microphone
          useEnglish={true}
          callback={(value) => {
            if (value) handleVoice(value, id);
            setMic(false);
          }}
          onClose={() => setMic(false)}
          content={content}
        />
      )}
    </div>
  );
};
