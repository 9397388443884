import Container from "./styles";

const Passengers = ({ passengers }) => {
  //@ts-ignore
  const serialNumbers = passengers.map((passenger, index) => index + 1);
  const names = passengers.map((passenger) => passenger.name);
  const ages = passengers.map((passenger) => passenger.age);
  const genders = passengers.map((passenger) => passenger.gender);
  const bookingStatuses = passengers.map(
    (passenger) =>
      passenger.atBooking.status +
      "/" +
      passenger.atBooking.coach +
      "/" +
      passenger.atBooking.seat +
      "/" +
      passenger.atBooking.berth,
  );
  const currentStatuses = passengers.map(
    (passenger) =>
      passenger.atCurrent.status +
      "/" +
      passenger.atCurrent.coach +
      "/" +
      passenger.atCurrent.seat +
      "/" +
      passenger.atCurrent.berth,
  );

  return (
    <Container>
      <h5>Passenger Details:</h5>

      <div className="table">
        <div>
          <p className="header">#</p>
          {serialNumbers.map((number) => (
            <p>{number}.</p>
          ))}
        </div>

        <div>
          <p className="header">Name</p>
          {names.map((name) => (
            <p>{name}</p>
          ))}
        </div>

        <div>
          <p className="header">Age</p>
          {ages.map((age) => (
            <p>{age}</p>
          ))}
        </div>

        <div>
          <p className="header">Gender</p>
          {genders.map((gender) => (
            <p>{gender}</p>
          ))}
        </div>

        <div>
          <p className="header">Booking Status</p>
          {bookingStatuses.map((status) => (
            <p>{status}</p>
          ))}
        </div>

        <div>
          <p className="header">Current Status</p>
          {currentStatuses.map((status) => (
            <p>{status}</p>
          ))}
        </div>
        <p>*Children under 5 years of age shall be carried free and no purchase of any ticket is required. (If no separate berth is opted.)</p>
      </div>
    </Container>
  );
};

export default Passengers;
