import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import styles from "./styles.module.css";
import langText from "../translations";
import useGetReduxState from "../../../Hooks/useGetReduxState";
import Speaker from "../../../UI/UIComponents/Speaker";
import audios from "../../../UI/UIComponents/Speaker/audios";
export const Header = ({ currentStep }) => {
  const lang: "en" | "gu" | "hi" = useGetReduxState().behaviour.lang;
  return (
    <div className={styles.stepper}>
      <div style={{}} className={styles.step}>
        {currentStep > 1 ? (
          <FontAwesomeIcon
            style={{
              background: "#326abb",
              color: "white",
              padding: "4px",
              fontSize: "12px",
              borderRadius: "50%",
              width: "13px",
              height: "13px",
            }}
            icon={faCheck}
          />
        ) : (
          <span>1</span>
        )}

        <p
          style={{
            width: "100%",

            left: "-72%",
          }}
        >
          {langText[lang].text3}
        </p>
      </div>
      <div
        style={{
          borderColor: currentStep > 2 ? "#326abb" : "#c3c3c3",
        }}
        className={styles.hr}
      ></div>
      <div className={styles.step} style={{}}>
        <span
          style={{
            backgroundColor: currentStep > 2 ? "#326abb" : "#dbdada",
            color: currentStep > 2 ? "white" : "rgb(85, 85, 85)",
          }}
        >
          2
        </span>
        <p
          style={{
            color: currentStep > 2 ? "#326abb" : "rgb(85, 85, 85)",
          }}
        >
          {langText[lang].text2}
        </p>
      </div>

      {/*  */}
    </div>
  );
};
