import { useState } from "react";
import useGetReduxState from "../../../../Hooks/useGetReduxState";
import langText from "./translations";
const Row = ({ label, price }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        fontSize: "14px",
        color: "#2c2c2c",
        fontWeight: "500",
        borderBottom: "1px solid #e6e3e3",
        padding: "10px 0px",
      }}
    >
      <p>{label}</p>
      <p>₹{price}</p>
    </div>
  );
};

const Fare = ({ fares }) => {
  const [show, setShow] = useState(false);
  const lang: "hi" | "en" | "gu" = useGetReduxState().behaviour.lang;
  return (
    <div style={{ width: "100%" }}>
      {show && (
        <div style={{ width: "100%", padding: "15px", paddingBottom: "0px" }}>
          <Row label={langText[lang].ticket} price={fares.ticket} />
          <Row label={langText[lang].irctc} price={fares.irctc} />
          <Row label={langText[lang].travel} price={fares.travel} />
          <Row label={langText[lang].ctc} price={fares.ctc} />
        </div>
      )}

      <div
        style={{
          padding: "15px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>
          <p style={{ fontSize: "18px" }}>{langText[lang].total}:</p>
          <button
            style={{
              fontSize: "12px",
              background: "white",
              border: "none",
              color: "#0b74fa",
              fontWeight: "600",
              marginTop: "5px",
              cursor: "pointer",
              boxShadow: "none",
            }}
            onClick={() => setShow((prev) => !prev)}
          >
            {show ? (
              <span>{langText[lang].hide}</span>
            ) : (
              <span>{langText[lang].show}</span>
            )}
          </button>
        </div>

        <p
          style={{
            fontSize: "22px",
            color: "#009688",
            fontWeight: "500",
            border: "1px solid",
            padding: "5px 15px",
            borderRadius: "5px",
          }}
        >
          ₹{fares.total}
        </p>
      </div>
    </div>
  );
};

export default Fare;
