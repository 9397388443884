import React from "react";
import { CloseDrawer } from "../../../Helpers/EventEmitter";
import useGetReduxState from "../../../Hooks/useGetReduxState";
import Spinner from "../../../UI/UIComponents/Spinner";

const Footer = ({ price, submit, loading }) => {
  const lang = useGetReduxState().behaviour.lang;

  const getText = () => {
    if (lang === "en") return "Back";
    if (lang === "hi") return "वापस जाएं";
    if (lang === "gu") return "પાછા";
  };

  const getText2 = () => {
    if (lang === "en") return "Review Journey";
    if (lang === "hi") return "यात्रा की समीक्षा";
    if (lang === "gu") return "પ્રવાસની સમીક્ષા કરો";
  };

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <button
        style={{
          boxShadow: "rgb(231 231 231 / 75%) 0px -4px 3px",
          width: "100%",
          border: "none",
          fontSize: "18px",
          padding: "14px",
          background: "white",
          color: "black",
          cursor: "pointer",
        }}
        onClick={() => CloseDrawer()}
      >
        <span>{getText()}</span>
      </button>
      <button
        style={{
          width: "100%",
          border: "1px solid #326abb",
          fontSize: "18px",
          padding: loading ? "11px" : "14px",
          background: "#326abb",
          color: "white",
          cursor: "pointer",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        onClick={submit}
      >
        {loading ? (
          <Spinner
            borderWidth={"3px"}
            activeColor={"#f9f9f9"}
            inactiveColor={"#326abb"}
            speed={"0.8s"}
            size={"25px"}
          />
        ) : (
          <React.Fragment>
            {lang === "en" ? (
              <span>Continue</span>
            ) : lang === "hi" ? (
              <span>जारी रखें</span>
            ) : (
              <span>ચાલુ રાખો</span>
            )} 
          </React.Fragment>
        )}
      </button>
    </div>
  );
};

export default Footer;
