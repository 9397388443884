import useGetReduxState from "../../../Hooks/useGetReduxState";
import langText from "./translations";
const disha =
  "https://cdn.jsdelivr.net/gh/corover/assets@latest/askdisha-bucket/favi.svg";

const Loader = () => {
  const lang = useGetReduxState().behaviour.lang;
  return (
    <div
      style={{
        textAlign: "center",
        position: "absolute",
        top: "50%",
        transform: "translateY(-60%)",
        color: "#9f9f9f",
        width: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img src={disha} alt="" style={{ width: "50px" }} />
      </div>

      <p
        style={{
          margin: 0,
          fontSize: "24px",
          color: "rgb(79,79,79)",
          letterSpacing: "3px",
          wordSpacing: "1px",
          marginTop: "2px",
        }}
      >
        {langText[lang].loader.header}
      </p>
      <p
        style={{
          margin: 0,
          fontSize: "14px",
          color: "rgb(157,157,157)",
          letterSpacing: "1px",
        }}
      >
        {langText[lang].loader.text}
      </p>
    </div>
  );
};

export default Loader;
