import useButtonQueries from "../../Hooks/useButtonQueries/useButtonQueries";
import useGetReduxState from "../../Hooks/useGetReduxState";
import Container from "./styles";
import langText from "./translations";

const icon =
  "https://cdn.jsdelivr.net/gh/corover/assets@latest/askdisha-bucket/Disha-400.svg";
const Fallback400 = () => {
  const lang: "hi" | "en" | "gu" = useGetReduxState().behaviour.lang;
  const { useBook, usePNR, useRefund } = useButtonQueries();
  return (
    <Container>
      <div className="first">
        <img src={icon} alt="" />
        <p>{langText[lang].text}</p>
      </div>

      <button onClick={useBook}>{langText[lang].buttons.book}</button>
      <button onClick={useRefund}>{langText[lang].buttons.refund}</button>
      <button onClick={usePNR}>{langText[lang].buttons.pnr}</button>

      <div className="helpline">
        <h6>{langText[lang].customer}</h6>
        <a href="tel:14646">14646</a>
        <p>{langText[lang].or}</p>
        <a href="tel:0755-6610661">0755-6610661</a> /{" "}
        <a href="tel:0755-4090600">0755-4090600</a>
        <p>({langText[lang].language}: Hindi and English)</p>
      </div>
    </Container>
  );
};

export default Fallback400;
