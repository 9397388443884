import moment from "moment";

import useGetReduxState from "../../Hooks/useGetReduxState";
import Alert from "../../UI/UIComponents/Alert";
import { CloseDrawer } from "../../Helpers/EventEmitter";
const NotAllowed = ({ For, date, handleClose }) => {

  const langText = ({ lang, date }) => {
    if (lang === "en")
      return {
        ok: "Okay",
        arp: `Booking for maximum ARP will open from 8:00 am, ${moment(
          date,
          "YYYYMMDD",
        ).format("DD MMMM")}`,
        sleeper: `Tatkal Booking for sleeper classes will open from 11:00 am, ${moment(
          date,
          "YYYYMMDD",
        ).format("DD MMMM")}`,
        ac: `Tatkal Booking for AC classes will open from 10:00 am, ${moment(
          date,
          "YYYYMMDD",
        ).format("DD MMMM")}`,
      };
    else {
      return {
        ok: "ठीक है",
        arp: `अधिकतम ARP के लिए बुकिंग ${moment(date, "YYYYMMDD").format(
          "DD MMMM",
        )}, 8:00 बजे से खुलेगी`,
        sleeper: `Sleeper classes के लिए तत्काल बुकिंग ${moment(
          date,
          "YYYYMMDD",
        ).format("DD MMMM")}, 11:00 बजे से खुलेगी।`,
        ac: `AC classes के लिए तत्काल बुकिंग ${moment(date, "YYYYMMDD").format(
          "DD MMMM",
        )}, 10:00 बजे से खुलेगी।`,
      };
    }
  };
  const lang = useGetReduxState().behaviour.lang;
  const { ok, arp, ac, sleeper } = langText({ lang: lang, date: date });
  const getContent = (For) => {
    if (For === "ARP") return arp;

    if (For === "SLEEPER") return sleeper;

    return ac;
  };
  return (
    <Alert
      header={""}
      primaryText={ok}
      content={getContent(For)}
      secondaryText={""}
      handleSecondaryClick={() => {}}
      handlePrimaryClick={handleClose}
    />
  );
};

export default NotAllowed;
