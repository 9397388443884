import styled from "styled-components";

const Container = styled.div`
  font-size: 8pt;
  padding: 10px;
  border-top: 2px solid;
  width: 100%;

  h4 {
    text-decoration: underline;
    font-weight: bold;
    font-size: 9pt;
  }

  .flex {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    column-gap: 0.7in;
  }
`;

export default Container;
