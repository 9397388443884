import * as JsEncryptModule from "jsencrypt";
import moment from "moment";
import { v4 as uuid4 } from "uuid";
import store from "../Store";
import Fuse from "fuse.js";

export let closeMic = null;
const Buffer = require("buffer/").Buffer;

export const upiHandlers = [
  "abcdicici",
  "abfspay",
  "abfspay",
  "amazonpay",
  "apl",
  "axisb",
  "axl",
  "bpaywallet",
  "dhani",
  "digikhata",
  "ebixcash",
  "fam",
  "fkaxis",
  "freoicici",
  "goaxb",
  "ibl",
  "icici",
  "icici",
  "icici",
  "idfcbank",
  "idfcbank",
  "idfcpay",
  "ikwik",
  "jupiteraxis",
  "liv",
  "mbk",
  "naviaxis",
  "niyoicici",
  "nye",
  "okaxis",
  "okhdfcbank",
  "okicici",
  "oksbi",
  "omni",
  "oxymoney",
  "paymoni",
  "paytm",
  "payu",
  "pinelabs",
  "pingpay",
  "pockets",
  "ptaxis",
  "pthdfc",
  "ptsbi",
  "rapl",
  "seyes",
  "shriramhdfcbank",
  "slice",
  "sliceaxis",
  "spicepay",
  "superyes",
  "tapicici",
  "timecosmos",
  "trans",
  "upi",
  "waaxis",
  "wahdfcbank",
  "waicici",
  "wasbi",
  "yapl",
  "ybl",
  "yesfam",
  "yesg",
  "yespop",
];

export const setMicClose = (_close) => {
  closeMic = _close;
};

export const updateUPIId = (upi) => {
  if (!upi) return upi;
  upi = upi.replaceAll(" ", "");
  upi = upi.replace("attherateof", "@");

  if (!upi.includes("@")) return upi;

  const [value, handler] = upi.split("@");

  const fuse = new Fuse(upiHandlers);
  let searchValue: any = fuse.search(handler);

  if (searchValue && searchValue[0]) {
    return `${value}@${searchValue[0].item}`;
  }

  return upi;
};

export const isShowMic = () => {
  if ("SpeechRecognition" in window || "webkitSpeechRecognition" in window) {
    return true;
  } else {
    return false;
  }
};

export const getChannel = () => {
  return window.location != window.parent.location
    ? document.referrer
    : document.location.href;
};

export const getDSession = () => {
  var jsEncrypt = new JsEncryptModule.JSEncrypt();
  // jsEncrypt.setPublicKey(Api.PUBLIC_KEY);
  jsEncrypt.setPublicKey(
    Buffer.from(process.env.REACT_APP_P865243658, "base64").toString()
  );

  let alphas = "abcdefghijklmnopqrstuvwxyz";
  let uid = uuid4();
  let randomKey = "";
  let distance = parseInt(process.env.REACT_APP_DISTANCE);
  for (let i = 0; i < distance; i++) {
    randomKey += alphas[Math.floor(Math.random() * alphas.length)];
  }
  let uidToken = uid.substring(0, 5) + randomKey + uid.substring(5);

  let dSession = jsEncrypt.encrypt(
    Buffer.from(uidToken).toString("base64") + "," + uidToken
  );
  return dSession;
};

export const getSessionId = () => {
  return uuid4();
};

export const isTestEnvironment = () => {
  const isLocalhost = Boolean(
    window.location.hostname === "localhost" ||
      // [::1] is the IPv6 localhost address.
      window.location.hostname === "[::1]" ||
      // 127.0.0.0/8 are considered localhost for IPv4.
      window.location.hostname.match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
      )
  );
  const isStaging = Boolean(window.location.href.includes("staging"));

  const isTest = Boolean(window.location.href.includes("test"));
  if (isLocalhost || isStaging || isTest) return true;

  return false;
};

// /////////////////////////////////////////////////////

const calcTime = () => {
  // create Date object for current location
  let d = new Date();

  // convert to msec
  // add local time zone offset
  // get UTC time in msec
  let utc = d.getTime() + d.getTimezoneOffset() * 60000;

  // create new Date object for different city
  // using supplied offset
  let nd = new Date(utc + 3600000 * 5.5);

  // return time as a string
  return {
    hour: nd.getHours(),
    minutes: nd.getMinutes(),
    date: nd.getDate(),
    month: nd.getMonth() + 1,
    year: nd.getFullYear(),
  };
};

const isMaintainanceTime = (h1, m1, h2, m2) => {
  // return false;
  const { hour, minutes } = calcTime();
  if ((hour >= h1 && minutes >= m1) || (hour <= h2 && minutes <= m2)) {
    return true;
  } else {
    return false;
  }
};

//@ts-ignore
const getMessage = ({ start, end, isDaily }) => {
  let startM = moment(start, "h:m").format("hh:mm A");
  let endM = moment(end, "h:m").format("hh:mm A");

  let lang = store.getState().behaviour.lang;

  if (lang === "en") {
    return `IRCTC's systems are undergoing  maintainance from ${startM} to ${endM}. We suggest you try booking after ${endM}.`;
  } else {
    return `IRCTC के सिस्टम  ${startM} बजे से ${endM} बजे तक मेंटेनेंस के दौर से गुजर रहे हैं। हमारा सुझाव है कि आप ${endM} के बाद बुकिंग करने का प्रयास करें।`;
  }
};

export const isBlockTime = () => {
  // let today = calcTime();

  if (isMaintainanceTime(23, 45, 0, 20))
    return {
      isBlock: true,
      message: getMessage({ start: `23:45`, end: "0:20", isDaily: true }),
    };

  return {
    isBlock: false,
    message: "",
  };
};

// ////////////////////

export const toTitleCase = (str) => {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export const makeArray = (array) => {
  if (Array.isArray(array)) return array;
  else return [array];
};
