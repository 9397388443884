import moment from "moment";
import { useEffect, useState } from "react";
import useGetReduxState from "../../../Hooks/useGetReduxState";
import { setJourneyDate } from "../../../Store/Dispatcher/journey";
import Drawer from "../../../UI/UIComponents/Drawer";
import { getAvailability, getPopUp } from "../utils";
import Content from "./Content";
import Footer from "./Footer";
import Header from "./Header";

const BookDate = ({
  train,
  trainClass,
  handleBookTicket,
  getClassFiltered,
  handleClose,
  updateCache,
}) => {
  const journey = useGetReduxState().journey;
  const [selectedTicket, setSelectedTicket] = useState(trainClass);
  const [selectedQuota, setSelectedQuota] = useState(journey.quota);
  const [error, setError] = useState("");

  const [availabilities, setAvailabilities] = useState([]);
  const [classObj, setClassObj] = useState(null);
  const [selectedDay, setSelectedDay] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");

  let originalTickets = train.tickets;
  const [tickets, setTickets] = useState(train.tickets);

  const [loading, setLoading] = useState(true);

  const [showAvailabilityPopUp, setShowAvailabilityPopUp] = useState("");
  // console.log(" showAvailabilityPopUp",showAvailabilityPopUp)
  const onBook = () => {
    if (loading) return;
    if (selectedStatus.includes("#")) {
      if (selectedQuota !== "TQ") {
        setShowAvailabilityPopUp("ARP");
        return;
      }

      let For = "";
      if (selectedTicket === "SL" || selectedTicket === "2S") For = "SLEEPER";
      else For = "AC";
      setShowAvailabilityPopUp(For);

      return;
    }

    let ticket = {
      class: selectedTicket,
      fare: classObj.totalFare,
      date: selectedDay,
      availablityStatus: selectedStatus,
      msg: getPopUp(classObj),
      error: false,
      avlDList: availabilities,
    };
    let train2 = train;
    train2.departureDate = moment(selectedDay, "D-M-YYYY").format("YYYYMMDD");
    train2.quota = selectedQuota;
    setJourneyDate(moment(selectedDay, "D-M-YYYY").format("YYYYMMDD"));
    handleBookTicket({ train: train2, ticket });
  };

  const onTatkal = (): boolean => {
    if (selectedStatus.includes("#")) {
      return true;
    } else {
      return false;
    }
  };

  const getColor = (status) => {
    let check = status.toLowerCase();

    if (
      check.includes("regret") ||
      check.includes("deleted") ||
      check.includes("suspended") ||
      check.includes("departed") ||
      check.includes("not")
    )
      return "#A5A4A6";

    if (
      check.includes("available") ||
      check.includes("curr_avbl") ||
      check.includes("rac")
    )
      return "#33A80E";

    if (check.includes("wl")) return "#E0880D";

    return "#A5A4A6";
  };

  const getT = async () => {
    setLoading(true);
    const t = await getAvailability({
      train: train,
      classes: [selectedTicket],
      quota: selectedQuota,
    });
    let obj = t[selectedTicket];
    setClassObj(obj);

    let avlDList = obj.avlDayList;
    if (!Array.isArray(avlDList)) {
      if (avlDList && !obj.hasOwnProperty("errorMessage")) {
        avlDList = [obj.avlDayList];
        // set Errors
      } else avlDList = [];
    }
    if (obj.hasOwnProperty("errorMessage")) {
      setError(obj.errorMessage.split("- (")[0]);
    } else {
      setError("");
    }
    setAvailabilities(avlDList);
    if (avlDList.length > 0) {
      setSelectedDay(avlDList[0].availablityDate);
      setSelectedStatus(avlDList[0].availablityStatus);
      updateCache({
        selectedQuota: selectedQuota,
        selectedClass: selectedTicket,
        train: train,
        selectedDate: moment(avlDList[0].availablityDate, "DD-MM-YYYY").format(
          "YYYYMMDD"
        ),
        fare: obj.totalFare,
        status: avlDList[0].availablityStatus,
      });
    }

    setLoading(false);
  };
  // console.log("selectedQuota, selectedTicket", selectedQuota, selectedTicket);
  useEffect(() => {
    getT();
  }, [selectedQuota, selectedTicket]);

  useEffect(() => {
    // getT();
    if (classObj) {
      let t = tickets.map((ticket) => {
        let tes = ticket;
        if (ticket.class === classObj.enqClass) {
          tes.fare = classObj.totalFare;
        }
        return tes;
      });

      setTickets(t);
    }
  }, [classObj]);

  useEffect(() => {
    setTickets(originalTickets);
  }, [selectedQuota]);

  const disableQuota = () => {
    let currentDate = moment(new Date(), "YYYYMMDD");
    if (moment(journey.date, "YYYYMMDD").diff(currentDate, "days") + 1 < 6) {
      return false;
    }
    return true;
  };
  let quotas = disableQuota() ? ["GN", "LD", "SS"] : ["GN", "LD", "TQ", "SS"];

  const handleDateChange = (day) => {
    // if (getColor(day.availablityStatus) === "#607d8b") return;
    setSelectedDay(day.availablityDate);
    setSelectedStatus(day.availablityStatus);
  };

  return (
    <Drawer
      header={<Header />}
      content={
        <Content
          train={train}
          getClassFiltered={getClassFiltered}
          quotas={quotas}
          selectedQuota={selectedQuota}
          setSelectedQuota={setSelectedQuota}
          tickets={tickets}
          selectedTicket={selectedTicket}
          setSelectedTicket={setSelectedTicket}
          loading={loading}
          error={error}
          availabilities={availabilities}
          handleDateChange={handleDateChange}
          selectedDay={selectedDay}
          getColor={getColor}
          showAvailabilityPopUp={showAvailabilityPopUp}
          setShowAvailabilityPopUp={setShowAvailabilityPopUp}
        />
      }
      footer={
        <Footer
          selectedStatus={selectedStatus}
          error={error}
          selectedTicket={selectedTicket}
          tickets={tickets}
          onBook={onBook}
          selectedDay={selectedDay}
          classObj={classObj}
          showAvailabilityPopUp={showAvailabilityPopUp}
          setShowAvailabilityPopUp={setShowAvailabilityPopUp}
          selectedQuota={selectedQuota}
        />
      }
      handleCloseDrawer={handleClose}
      showCross={true}
      fullHeight={true}
      showHeaderSeperator={true}
      onEnter={onBook}
      tatkal={onTatkal()}
    />
  );
};

export default BookDate;
