import store from "../../../../../../Store";
import langText from "./translations";
const getLang = () => {
  const lang: "hi" | "en" | "gu" = store.getState().behaviour.lang;

  return langText[lang].validations;
};

export const isValidGstNumber = (gstnumber) => {
  if (gstnumber == "")
    return {
      isError: false,
      errorMsg: "",
    };

  if (
    /^([0]{1}[1-9]{1}|[1]{1}[0-9]{1}|[2]{1}[0-7]{1}|[2]{1}[9]{1}|[3]{1}[0-7]{1})[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}[a-zA-Z0-9]{3}$/.test(
      gstnumber,
    ) === false
  )
    return {
      isError: true,
      errorMsg: getLang().valid,
    };

  return {
    isError: false,
    errorMsg: "",
  };
};

export const isValidPin = (pin) => {
  if (pin == "")
    return {
      isError: false,
      errorMsg: "",
    };

  if (/(^\d{6}$)/.test(pin) === false)
    return {
      isError: true,
      errorMsg: getLang().validPin,
    };

  return {
    isError: false,
    errorMsg: "",
  };
};

export const isValidFlatNumber = (flatNumber) => {
  if (flatNumber == "")
    return {
      isError: false,
      errorMsg: "",
    };

  if (flatNumber.length < 3 || flatNumber.length > 30)
    return {
      isError: true,
      errorMsg: getLang().validFlat,
    };

  return {
    isError: false,
    errorMsg: "",
  };
};

const removeExtraSpaces = (sentence: string) => {
  return sentence.toString().replace(/^\s+|\s+$/g, "");
};

export const missingFields = (gst) => {
  let newP = { ...gst };
  let missing = false;
  if (removeExtraSpaces(gst.values.gstnumber) === "") {
    missing = true;
    newP.errors.gstnumber = getLang().number;
  }
  if (removeExtraSpaces(gst.values.gstcompany) === "") {
    missing = true;

    newP.errors.gstcompany = getLang().company;
  }
  if (removeExtraSpaces(gst.values.gstflat) === "") {
    missing = true;

    newP.errors.gstflat = getLang().flat;
  }
  if (removeExtraSpaces(gst.values.gststreet) === "") {
    missing = true;

    newP.errors.gststreet = getLang().street;
  }
  if (removeExtraSpaces(gst.values.gstarea) === "") {
    missing = true;

    newP.errors.gstarea = getLang().area;
  }
  if (removeExtraSpaces(gst.values.gststate) === "") {
    missing = true;

    newP.errors.gststate = getLang().state;
  }
  if (removeExtraSpaces(gst.values.gstpincode) === "") {
    missing = true;

    newP.errors.gstpincode = getLang().pincode;
  }
  if (removeExtraSpaces(gst.values.gstcity) === "") {
    missing = true;

    newP.errors.gstcity = getLang().city;
  }

  return { isMissing: missing, passengerSet: newP };
};
