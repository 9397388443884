const translations = {
  en: {
    header: "OTP Verification",
    steps: {
      id: "Verify IRCTC ID",
      payment: "Complete Payment",
      otp: "Verify OTP and proceed to pay",
    },
    text1:
      "After successful payment, you will get an OTP for booking on Mobile Number and Email ID registered with IRCTC for User ID",
    subtext1:
      "You will have to enter and verify that OTP in order to complete the booking.",
    text2: "Kindly input the One-Time Password (OTP) sent to your mobile number ending with ",
    text3: " or the email address ",
    text5:" registered with your IRCTC UserId to continue with the booking process.",
    text4:
      "In case payment is deducted and ticket is not booked due to browser refresh or closed by mistake, you can retry booking through AskDISHA within 15 mins.",
    button: "Yes, I understand",

    otp: {
      header: "OTP Verification",
      subtext: "Enter the OTP sent to ",
      placeholder: "Enter your OTP",
      didnt: "Didn't recieve the OTP?",
      resendin: "Resend OTP in ",
      resendbtn: "Resend OTP",
    },
  },
  hi: {
    header: "अगले चरण",
    steps: {
      id: "IRCTC ID की पुष्टि",
      payment: "भुगतान करें",
      otp: "ओटीपी सत्यापित करें और भुगतान के लिए आगे बढ़ें",
    },
    text1: "सफल भुगतान के बाद, आपको User ID",
    subtext1:
      "के लिए IRCTC के साथ पंजीकृत मोबाइल नंबर और Email ID पर बुकिंग के लिए एक OTP मिलेगा। बुकिंग पूरी करने के लिए आपको उस OTP को दर्ज और पुष्टि करना होगा।",
    text2: "बुकिंग जारी रखने के लिए कृपया ",
    text3: " से समाप्त होने वाले आपके मोबाइल नंबर या आपके आईआरसीटीसी उपयोगकर्ता आईडी के साथ पंजीकृत ईमेल पते ",
    text5:" पर भेजे गए वन-टाइम पासवर्ड (ओटीपी) को इनपुट करें।",
    text4:
      "यदि भुगतान काटा जाता है और ब्राउजर रिफ्रेश होने या गलती से बंद होने के कारण टिकट बुक नहीं होता है, तो आप 15 मिनट के भीतर AskDisha के माध्यम से बुकिंग का पुनः प्रयास कर सकते हैं।",
    button: "हाँ, मैं समझता हूँ",

    otp: {
      header: "OTP की पुष्टि",
      subtext: " पर भेजे गए OTP को दर्ज करें",
      placeholder: "अपना OTP दर्ज करें",
      didnt: "OTP प्राप्त नहीं हुआ?",
      resendin: " में OTP दोबारा भेजें",
      resendbtn: "OTP पुनः भेजें",
    },
  },
  gu: {
    header: "આગામી પગલું",
    steps: {
      id: "આઈઆરસીટીસી આઈડીની ચકાસણી",
      payment: "ચૂકવણી કરો",
      otp: "ઓટિપી ચકાસો અને ચૂકવણી કરવા આગળ વધો",
    },
    text1: "સફળ ચુકવણી પછી, તમને એક વપરાશકર્તા ID પ્રાપ્ત થશે.",
    subtext1:
      "આઈ. આર. સી. ટી. સી. સાથે નોંધાયેલા મોબાઇલ નંબર અને ઈ-મેલ આઈડી પર બુકિંગ માટે એક ઓ. ટી. પી. પ્રાપ્ત થશે. તમારે બુકિંગ પૂર્ણ કરવા માટે તે ઓ. ટી. પી. દાખલ કરવાની અને તેની પુષ્ટિ કરવાની જરૂર પડશે.",
    text2: "બુકિંગ ચાલુ રાખવા માટે કૃપા કરીને ",
    text3: " સાથેના તમારા મોબાઇલ નંબર પર મોકલવામાં આવેલ વન-ટાઇમ પાસવર્ડ (OTP) ઇનપુટ કરો અથવા તમારા IRCTC UserId સાથે નોંધાયેલું ઇમેઇલ સરનામું ",
    text5:" દાખલ કરો. પ્રક્રિયા",
    text4:
      "જો ચુકવણી કાપવામાં આવે અને બ્રાઉઝર રિફ્રેશ થવાને કારણે અથવા આકસ્મિક રીતે બંધ થવાને કારણે ટિકિટ બુક કરવામાં ન આવે, તો તમે 15 મિનિટની અંદર આસ્કદિશા દ્વારા બુકિંગનો ફરીથી પ્રયાસ કરી શકો છો.",
    button: "હા, હું સમજું છું.",

    otp: {
      header: "ઓટિપી ચકાસણી",
      subtext: "મોકલવામાં આવેલ ઓ. ટી. પી. દાખલ કરો",
      placeholder: "તમારો ઓટિપી દાખલ કરો",
      didnt: "શું તમને ઓ. ટી. પી. મળ્યો નથી?",
      resendin: "પર ફરીથી ઓ. ટી. પી. મોકલો",
      resendbtn: "ઓ. ટી. પી. ફરીથી મોકલો",
    },
  },
};

export default translations;
