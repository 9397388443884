import { useEffect, useState } from "react";
import { useTimer } from "react-timer-hook";
import { IMAGE_MIC } from "../../../Assets";
import { Microphone } from "../../../Chatbot/Microphone";
import { isShowMic } from "../../../Helpers/misc";
import Container from "./styles";
import langText from "../translations";
import useGetReduxState from "../../../Hooks/useGetReduxState";

const phone =
  "https://cdn.jsdelivr.net/gh/corover/assets@latest/askdisha-bucket/phone-refactored.svg";
const otpIcon =
  "https://cdn.jsdelivr.net/gh/corover/assets@latest/askdisha-bucket/otp-refactored.svg";

const check =
  "https://cdn.jsdelivr.net/gh/corover/assets@latest/askdisha-bucket/check-refactored.png";
const nameIcon =
  "https://cdn.jsdelivr.net/gh/corover/assets@latest/askdisha-bucket/name-refactored.png";

const translations = {
  en: {
    mobileNo: "10 digit mobile number",
    otp: "6 digit OTP ",
    passName: "Passenger's Name",
  },
  hi: {
    mobileNo: "10 अंकों का मोबाइल नंबर",
    otp: "6 अंकों का OTP",
    passName: "यात्री का नाम",
  },

  gu: {
    mobileNo: "10 અંકનો મોબાઈલ નંબર",
    otp: "6 અંકનો OTP",
    passName: "મુસાફરનું નામ",
  },
};
const Phone = ({
  getOTP,
  component,
  phoneNumber,
  handlePhoneNumberChange,
  error,
  reEnter,
}) => {
  const lang: "hi" | "en" | "gu" = useGetReduxState().behaviour.lang;
  const [mic, setMic] = useState(false);
  const handleKeyPress = (event) => {
    if (event.charCode == 13 && !event.shiftKey) {
      getOTP();
    }
  };

  return (
    <div className="phone">
      <div className="header">
        <img src={phone} alt="" />
        <div>
          <h4>{langText[lang].phone.header}</h4>
          <p>{langText[lang].phone.text}</p>
          <span>{langText[lang].phone.subtext}</span>
        </div>
      </div>
      <div className="input">
        <div className="prefix">+91</div>
        <input
          type="number"
          placeholder={langText[lang].phone.placeholder}
          autoFocus={true}
          readOnly={component !== "PHONE"}
          maxLength={10}
          value={phoneNumber}
          onChange={(e) => {
            handlePhoneNumberChange(e.target.value);
          }}
          onKeyPress={handleKeyPress}
        />
        {component === "PHONE" && isShowMic() && (
          <img
            className="mic-btn"
            id="mic"
            src={IMAGE_MIC}
            alt=""
            onClick={() => setMic(true)}
          />
        )}
        {component !== "PHONE" && <button onClick={reEnter}>EDIT</button>}
      </div>
      {component === "PHONE" && error && (
        <p style={{ fontSize: "12px", color: "tomato", fontWeight: "500" }}>
          {error}
        </p>
      )}

      {component !== "PHONE" && (
        <div className="sent">
          <img src={check} alt="" />
          <p>{langText[lang].phone.success}</p>
        </div>
      )}

      {mic && (
        <Microphone
          useEnglish={true}
          callback={(value) => {
            if (value) handlePhoneNumberChange(value.split(" ").join(""));
            setMic(false);
          }}
          onClose={() => setMic(false)}
          content={translations[lang].mobileNo}
        />
      )}
    </div>
  );
};

const OTP = ({
  sendOTP,
  phone,
  otp,
  handleOTPChange,
  error,
  reSend,
  loading,
}) => {
  const [mic, setMic] = useState(false);
  const lang: "hi" | "en" | "gu" = useGetReduxState().behaviour.lang;

  useEffect(() => {
    let ac = new AbortController();
    setTimeout(
      () => {
        // abort after 10 minutes
        ac.abort();
      },
      10 * 60 * 1000
    );
    navigator.credentials
      ?.get({
        // @ts-ignore
        otp: { transport: ["sms"] },
        signal: ac.signal,
      })
      .then((otp) => {
        // @ts-ignore
        handleOTPChange(otp.code);

        //console.log("your otp code is", otp.code);
      })
      .catch((err) => {
        console.log(err);
      });
  });

  const time = new Date();
  time.setSeconds(time.getSeconds() + 30);
  const {
    seconds,

    restart,
  } = useTimer({ expiryTimestamp: time });

  const handleKeyPress = (event) => {
    if (event.key == "Enter" && !loading) {
      sendOTP();
    }
  };

  const getSeconds = (seconds) => {
    if (lang === "en")
      return (
        <span>
          {langText[lang].otp.resendin} {seconds}s
        </span>
      );
    else
      return (
        <span>
          {" "}
          {seconds}s {langText[lang].otp.resendin}
        </span>
      );
  };

  return (
    <div className="otp-container">
      <div className="header">
        <img src={otpIcon} alt="" />
        <div>
          <h4>{langText[lang].otp.header}</h4>
          {lang === "en" ? (
            <p>
              {langText[lang].otp.subtext} <span>{phone}</span>
            </p>
          ) : (
            <p>
              <span>{phone}</span> {langText[lang].otp.subtext}
            </p>
          )}
        </div>
      </div>
      <div className="input">
        <input
          type="number"
          placeholder={langText[lang].otp.placeholder}
          autoFocus={true}
          maxLength={6}
          onChange={(e) => {
            handleOTPChange(e.target.value);
          }}
          is="one-time-code"
          value={otp}
          onKeyDown={handleKeyPress}
        />
        {isShowMic() && (
          <img
            className="mic-btn"
            id="mic"
            src={IMAGE_MIC}
            alt=""
            onClick={() => setMic(true)}
          />
        )}
      </div>
      {error && (
        <p style={{ fontSize: "12px", color: "tomato", fontWeight: "500" }}>
          {error}
        </p>
      )}
      <div className="retry">
        <p>{langText[lang].otp.didnt}</p>
        {seconds > 0 && getSeconds(seconds)}

        {seconds < 1 && (
          <button
            onClick={() => {
              reSend();
              const time = new Date();
              time.setSeconds(time.getSeconds() + 30);
              restart(time);
            }}
          >
            {langText[lang].otp.resendbtn}
          </button>
        )}
      </div>

      {mic && (
        <Microphone
          useEnglish={true}
          callback={(value) => {
            if (value) handleOTPChange(value.split(" ").join(""));
            setMic(false);
          }}
          onClose={() => setMic(false)}
          content={translations[lang].otp}
        />
      )}
    </div>
  );
};

const Name = ({ sendName, name, handleNameChange, error }) => {
  const [mic, setMic] = useState(false);
  const lang: "hi" | "en" | "gu" = useGetReduxState().behaviour.lang;
  const handleKeyPress = (event) => {
    if (event.charCode == 13 && !event.shiftKey) {
      sendName();
    }
  };
  return (
    <div className="otp-container" style={{ padding: "15px" }}>
      <div className="header">
        <img src={nameIcon} alt="" style={{ width: "30px" }} />
        <div>
          <h4>{langText[lang].name.header}</h4>
          <p>{langText[lang].name.subtext}</p>
        </div>
      </div>
      <div
        className="input"
        style={{ marginTop: "20px", padding: "12px 15px" }}
      >
        <input
          type="text"
          placeholder={langText[lang].name.placeholder}
          autoFocus={true}
          onChange={(e) => {
            handleNameChange(e.target.value);
          }}
          style={{ textAlign: "left" }}
          value={name}
          onKeyPress={handleKeyPress}
        />
        {isShowMic() && (
          <img
            className="mic-btn"
            id="mic"
            src={IMAGE_MIC}
            alt=""
            onClick={() => setMic(true)}
          />
        )}
      </div>
      {error && (
        <p style={{ fontSize: "12px", color: "tomato", fontWeight: "500" }}>
          {error}
        </p>
      )}

      {mic && (
        <Microphone
          useEnglish={false}
          callback={(value) => {
            if (value) handleNameChange(value);
            setMic(false);
          }}
          onClose={() => setMic(false)}
          content={translations[lang].passName}
        />
      )}
    </div>
  );
};

const Content = ({
  reEnter,
  sendOTP,
  getOTP,
  values,
  handle,
  error,
  component,
  loading,
  sendName,
}) => {
  return (
    <Container>
      {component !== "NAME" && (
        <Phone
          phoneNumber={values.phoneNumber}
          handlePhoneNumberChange={handle.handlePhoneNumberChange}
          error={error}
          component={component}
          getOTP={getOTP}
          reEnter={reEnter}
        />
      )}

      {component === "OTP" && (
        <OTP
          sendOTP={sendOTP}
          otp={values.otp}
          handleOTPChange={handle.handleOTPChange}
          error={error}
          reSend={handle.reSendOTP}
          phone={values.phoneNumber}
          loading={loading}
        />
      )}

      {component === "NAME" && (
        <Name
          name={values.name}
          handleNameChange={handle.handleNameChange}
          error={error}
          sendName={sendName}
        />
      )}
    </Container>
  );
};

export default Content;
