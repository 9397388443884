import moment from "moment";
import store from "../../../../../../../Store";
import { isAdult, isChild } from "../../../../../utils";
import langText from "./translations";
const getLang = () => {
  const lang = store.getState().behaviour.lang;

  return langText[lang];
};

export const isValidName = (name) => {
  if (name == "")
    return {
      isError: false,
      errorMsg: "",
    };

  if (/^[a-zA-Z]+(([ ][a-zA-Z ])?[a-zA-Z]*)*$/.test(name) === false)
    return {
      isError: true,
      errorMsg: getLang().validName,
    };

  if (name.length < 3 || name.length > 16)
    return {
      isError: true,
      errorMsg: getLang().nameLimit,
    };
  return {
    isError: false,
    errorMsg: "",
  };
};

export const isValidAge = (age) => {
  if (age === "")
    return {
      isError: false,
      errorMsg: "",
    };

  if (age.toString().indexOf(".") !== -1)
    return {
      isError: true,
      errorMsg: getLang().validAge,
    };

  if (age > 125)
    return {
      isError: true,
      errorMsg: getLang().maxAge,
    };

  if (age < 0)
    return {
      isError: true,
      errorMsg: getLang().minAge,
    };

  return {
    isError: false,
    errorMsg: "",
  };

  // if (entity === "ADULT") {
  //   if (age > 125) {
  //     return {
  //       isError: true,
  //       errorMsg: "Maximum allowed age is 125",
  //     };
  //   }
  //   if (age < 12)
  //     return {
  //       isError: true,
  //       errorMsg: "Age for Adult should be more than 11 years",
  //     };

  //   return {
  //     isError: false,
  //     errorMsg: "",
  //   };
  // }

  // if (entity === "CHILD") {
  //   if (age < 5 || age > 11)
  //     return {
  //       isError: true,
  //       errorMsg: "Age for Child should be 5 to 11 years",
  //     };
  //   else
  //     return {
  //       isError: false,
  //       errorMsg: "",
  //     };
  // }

  // if (entity === "INFANT") {
  //   if (age < 0 || age > 4)
  //     return {
  //       isError: true,
  //       errorMsg: "Age for Infant should be 0 to 4 years",
  //     };
  //   else
  //     return {
  //       isError: false,
  //       errorMsg: "",
  //     };
  // }
};

export const isValidDob = (dob) => {
  if (!moment(dob, "D-M-YYYY", true).isValid())
    return {
      isError: true,
      errorMsg: getLang().dob,
    };

  return {
    isError: false,
    errorMsg: "",
  };
};

export const isValidPassport = (passport) => {
  const regex = new RegExp("^[A-PR-WYa-pr-wy][1-9]" + "\\d\\s?\\d{4}[1-9]$");

  if (passport == "")
    return {
      isError: false,
      errorMsg: "",
    };

  if (regex.test(passport) === false)
    return {
      isError: true,
      errorMsg: getLang().passport,
    };
  else
    return {
      isError: false,
      errorMsg: "",
    };
};

export const isAgeMatch = (passenger) => {
  let age = removeExtraSpaces(passenger.values.age);
  let dob = passenger.values.dob;
  var years = moment().diff(moment(dob, "DD-MM-YYYY"), "years", false);
  if (age === "" || dob === "")
    return {
      isError: false,
      errorMsg: "",
    };

  if (years !== parseInt(age))
    return {
      isError: true,
      errorMsg: getLang().match,
    };
  else
    return {
      isError: false,
      errorMsg: "",
    };
};

const removeExtraSpaces = (sentence: string) => {
  return sentence.toString().replace(/^\s+|\s+$/g, "");
};

export const missingFields = (passenger, config) => {
  let newP = { ...passenger };
  let missing = false;

  if (removeExtraSpaces(passenger.values.name) === "") {
    missing = true;
    newP.errors.name = getLang().missing.name;
  }
  if (removeExtraSpaces(passenger.values.age) === "") {
    missing = true;

    newP.errors.age = getLang().missing.age;
  }
  if (passenger.values.gender === "") {
    missing = true;

    newP.errors.gender = getLang().missing.gender;
  }
  if (
    (isAdult(passenger.values.age) || isChild(passenger.values.age)) &&
    passenger.values.food === "" &&
    config.showFoodChoice
  ) {
    missing = true;

    newP.errors.food = getLang().missing.food;
  }
  if (
    (isAdult(passenger.values.age) || isChild(passenger.values.age)) &&
    passenger.values.nationality === ""
  ) {
    missing = true;

    newP.errors.nationality = getLang().missing.nationality;
  }

  if (
    (isAdult(passenger.values.age) || isChild(passenger.values.age)) &&
    passenger.values.nationality !== "IN"
  ) {
    if (passenger.values.passport === "") {
      missing = true;

      newP.errors.passport = getLang().missing.passport;
    }

    if (passenger.values.dob === "") {
      missing = true;

      newP.errors.dob = getLang().missing.dob;
    }
  }
  //add for other fields

  return { isMissing: missing, passengerSet: newP };
};
