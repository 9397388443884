import axios from "axios";
import { getChannel, getDSession } from "../Helpers/misc";
import store from "../Store";
import getHeaders, { isUnAuthorised } from "./getHeaders";
import { v4 as uuid4 } from "uuid";

let cancelToken;

export const getTrains = async ({ source, destination, date, quota }) => {
  if (cancelToken) {
    cancelToken.cancel("Operation cancelled due to new request");
  }
  cancelToken = axios.CancelToken.source();

  const configuration = store.getState().app;
  let payload = {
    userAgent: window.navigator.userAgent,
    source: source,
    destination: destination,
    journeyDate: date,
    jQuota: quota,
    userToken: configuration.userToken || uuid4(),
    channel: getChannel(),
    dSession: getDSession(),
    deviceId: configuration.deviceId,
    sessionId: configuration.sessionId,
    status: 1,
  };

  try {
    const response = await axios.post(
      `/dishaAPI/bot/editTrains`,

      payload,
      {
        headers: getHeaders(),
        cancelToken: cancelToken.token,
      },
    );

    return response.data;
  } catch (e) {
    await isUnAuthorised(e.response);
    return {
      error: "Error while retrieving train between stations",
      trainBtwnStnsList: [],
    };
  }
};

export const getStatus = async ({
  trainNumber,
  from,
  to,
  journeyDate,
  avlClasses,
  jQuota,
}) => {
  if (cancelToken) {
    cancelToken.cancel("Operation cancelled due to new request");
  }
  cancelToken = axios.CancelToken.source();

  const configuration = store.getState().app;
  // const journey = store.getState().journey;
  let payload = {
    userAgent: window.navigator.userAgent,
    trainNumber,
    from,
    to,
    journeyDate,
    avlClasses,
    jQuota,
    userToken: configuration.userToken || null,
    channel: getChannel(),
    dSession: getDSession(),
    sessionId: configuration.sessionId,
  };

  try {
    const response = await axios.post(
      `/dishaAPI/bot/getStatus`,

      payload,
      {
        headers: getHeaders(),
        cancelToken: cancelToken.token,
      },
    );

    return response.data;
  } catch (e) {
    await isUnAuthorised(e.response);
    return {
      error: true,
    };
  }
};

export const getSchedule = async ({ trainNumber, date, station }) => {
  if (cancelToken) {
    cancelToken.cancel("Operation cancelled due to new request");
  }
  cancelToken = axios.CancelToken.source();

  try {
    const response = await axios.get(
      `/dishaAPI/bot/trnscheduleEnq/${trainNumber}?journeyDate=${date}&startingStationCode=${station}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
        data: {},
      },
    );

    if (response.data?.stationList) {
      let stationList = response.data?.stationList;
      const current = stationList.filter(
        (stat) => stat.stationCode === station,
      )[0];
      const currentDayCount = parseInt(current.dayCount);
      const actualDayCountDifference = currentDayCount - 1;

      stationList = stationList.map((stat) => {
        return {
          ...stat,
          dayCount: (stat.dayCount - actualDayCountDifference).toString(),
        };
      });

      return {
        ...response.data,
        stationListUpdated: stationList,
      };
    } else return null;
  } catch (e) {
    console.log(e);
    return {
      error: true,
    };
  }
};
