import useGetReduxState from "../../Hooks/useGetReduxState";
import langText from "./translations";
const Maintainance = ({ isFromIRCTC, message }) => {
  const lang: "hi" | "en" | "gu" = useGetReduxState().behaviour.lang;
  return (
    <div
      style={{
        borderRadius: "7px",
        display: "flex",
        padding: "20px 15px",
        width: "90%",
        justifyContent: "flex-start",
        alignItems: "center",
        boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
      }}
    >
      <img
        style={{ width: "60px", marginRight: "10px" }}
        src={
          "https://cdn.jsdelivr.net/gh/corover/assets@latest/askdisha-bucket/disha-sleeping.gif"
        }
        alt="Server Under Maintainance"
      />

      <div>
        {isFromIRCTC ? (
          <p
            style={{
              fontSize: "16px",
              color: "#d75859",
              fontWeight: "500",
              marginBottom: "5px",
            }}
          >
            {langText[lang].headerIRCTC}
          </p>
        ) : (
          <p
            style={{
              fontSize: "16px",
              color: "#d75859",
              fontWeight: "500",
              marginBottom: "5px",
            }}
          >
            {langText[lang].headerDisha}
          </p>
        )}

        {isFromIRCTC ? (
          <p
            style={{
              fontSize: "14px",
              color: "#545454",
              margin: "0",
              textAlign: "justify",
            }}
          >
            {message}
          </p>
        ) : (
          <p
            style={{
              fontSize: "14px",
              color: "#545454",
              margin: "0",
              textAlign: "justify",
            }}
          >
            {langText[lang].text}
          </p>
        )}
      </div>
    </div>
  );
};

export default Maintainance;
