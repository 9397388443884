import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { useTimer } from "react-timer-hook";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import useBookingFlow from "../../Hooks/useBookingFlow";

import useGetReduxState from "../../Hooks/useGetReduxState";
import langText from "./translation";
import styled from "styled-components";
import { Icon } from "react-icons-kit";
import { eyeOff } from "react-icons-kit/feather/eyeOff";
import { eye } from "react-icons-kit/feather/eye";
import { setOtpValue } from "../../Store/Dispatcher/passengerForm";
import { setVerifyOTPData } from "../../Store/Dispatcher/passengerForm";
import { Microphone } from "../../Chatbot/Microphone";
import { faMicrophone } from "@fortawesome/free-solid-svg-icons";

const RetryContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 12px;
`;

const TextParagraph = styled.p`
  font-size: 12px;
  font-weight: 400;
  margin-right: 6px;
`;

const TextSpan = styled.span`
  font-size: 12px;
  font-weight: 600;
`;

const RetryButton = styled.button`
  font-size: 14px;
  background: white;
  border: none;
  font-weight: 600;
  color: #5287e7;
  cursor: pointer;
`;

const Content = ({ loading, sendOTP }) => {
  const lang = useGetReduxState().behaviour.lang;
  const { mobile, email } = useGetReduxState().app.irctcDetails;
  const OTPvalid = useGetReduxState().passengerform.otpData;
  const otpView = useGetReduxState().passengerform.otpView;
  const [mic, setMic] = useState(false);
  const [password, setPassword] = useState("");
  const [type, setType] = useState("password");
  const [icon, setIcon] = useState(eyeOff);

  const getText = () => {
    if (lang === "en") return "Invalid OTP";
    if (lang === "hi") return "अमान्य OTP";
    if (lang === "gu") return "અમાન્ય OTP";
  };

  useEffect(() => {
    let ac = new AbortController();
    setTimeout(
      () => {
        // abort after 10 minutes
        ac.abort();
      },
      10 * 60 * 1000
    );
    navigator.credentials
      ?.get({
        // @ts-ignore
        otp: { transport: ["sms"] },
        signal: ac.signal,
      })
      .then((otp) => {
        // @ts-ignore
        handleOTPChange(otp.code);
      })
      .catch((err) => {
        console.log(err);
      });

    //Cleanup function to set error to false on component unmount
    return () => {
      setVerifyOTPData("");
    };
  }, []);

  const time = new Date();
  time.setSeconds(time.getSeconds() + 30);
  const {
    seconds,

    restart,
  } = useTimer({ expiryTimestamp: time });

  const handleKeyPress = (event) => {
    if (event.key == "Enter" && !loading) {
      sendOTP();
    }
  };

  const getSeconds = (seconds) => {
    if (lang === "en")
      return (
        <span>
          {langText[lang].otp.resendin} {seconds}s
        </span>
      );
    else
      return (
        <span>
          {" "}
          {seconds}s {langText[lang].otp.resendin}
        </span>
      );
  };

  const handleToggle = () => {
    if (type === "password") {
      setIcon(eye);
      setType("text");
    } else {
      setIcon(eyeOff);
      setType("password");
    }
  };

  const handleVoice = (value) => {
    if (!value) return;
    let otp = value.trim().split(" ").join("");
    setPassword(otp);
    setOtpValue(otp);
  };

  return (
    <div style={{ padding: "15px", fontFamily: "'Montserrat',sans-serif" }}>
      {(
        <React.Fragment>
          <p
            style={{
              margin: "0",
              lineHeight: "20px",
              color: "#373838",
              fontWeight: "600",
              fontSize: "14px",
            }}
          >
            {langText[lang].text2}{" "}
            <span
              style={{
                fontSize: "16px",
                fontWeight: "600",
                color: "#2196f3",
              }}
            >
              {mobile}
            </span>{" "}
            {langText[lang].text3}{" "}
            <span
              style={{
                fontSize: "16px",
                fontWeight: "600",
                color: "#2196f3",
              }}
            >
              {email}
            </span>
            {langText[lang].text5}{" "}
            <span
              style={{
                fontSize: "16px",
                fontWeight: "600",
                color: "#2196f3",
              }}
            ></span>
          </p>

          <div
            style={{
              width: "100%",
              borderTop: "1px solid gray",
              margin: "8px 0px",
            }}
          ></div>
        </React.Fragment>
      )}

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          width: "100%",
          marginTop: "10px",
        }}
        className="steps"
      >
        <div
          style={{
            textAlign: "center",
          }}
        >
          <input
            className="inputSchemes"
            type={type}
            name="password"
            placeholder="******"
            maxLength={6}
            value={password}
            style={{
              width: "60%",
              fontSize: "24px",
              fontWeight: "600",
              color: "rgb(91, 91, 91)",
              marginRight: "6%",
              padding: "10px 0px",
              outline: "none",
              borderRadius: "10px",
              letterSpacing: "12px",
              textAlign: "center",
              border: "1px solid rgb(50, 106, 187)",
            }}
            onKeyDown={handleKeyPress}
            onChange={(e) => {
              setPassword(e.target.value);
              setOtpValue(e.target.value);
            }}
            autoComplete="current-password"
          />
          <FontAwesomeIcon
            style={{
              position: "relative",
              top: "6px",
              transform: "translateY(-50%)",
              right: "13%",
              fontSize: "17px",
              color: "#2d69c0",
              cursor: "pointer",
            }}
            icon={faMicrophone}
            onClick={() => setMic(true)}
          />
          <span title="Show/Hide" onClick={handleToggle}>
            <Icon icon={icon} size={25} />
          </span>
          {password && (
            <span
              style={{
                fontSize: "12px",
                lineHeight: "1.1",
                display: "block",
                marginTop: "5px",
                color: "slategray",
              }}
            ></span>
          )}
          {mic && (
            <Microphone
              useEnglish={true}
              callback={(value) => {
                handleVoice(value);
                setMic(false);
              }}
              onClose={() => setMic(false)}
              content=""
            />
          )}
        </div>
        {OTPvalid.data === "false" && (
          <p
            style={{
              fontSize: "small",
              color: "tomato",
              margin: "5px 0px 0px",
              padding: "0px",
            }}
          >
            {getText()}
          </p>
        )}

        <RetryContainer>
          <TextParagraph>{langText[lang].otp.didnt}</TextParagraph>
          <TextSpan> {seconds > 0 && getSeconds(seconds)}</TextSpan>

          {seconds < 1 && (
            <RetryButton
              onClick={() => {
                sendOTP();
                const time = new Date();
                time.setSeconds(time.getSeconds() + 30);
                restart(time);
              }}
            >
              {langText[lang].otp.resendbtn}
            </RetryButton>
          )}
        </RetryContainer>
      </div>
    </div>
  );
};

export default Content;
