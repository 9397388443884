const translations = {
  en: {
    loader: {
      header: "Please Wait..",
      text: "Initialising Payment Gateway.",
    },
    selectMode: {
      error: "Enter a valid UPI ID manually or by voice",
      header: "Please select the payment mode",
      footer: "Please provide your UPI ID to continue",
      placeHolder: "Enter your UPI ID",
      timer: "Please complete your payment before the timer",
      confirmUPI: "- Confirm UPI ID -",
      approvePayment: "- Approve Payment -",
      isCorrect: "Is this correct?",
      openUPI: "Open your UPI App to Pay",
      edit: "Please edit if required.",
      minLeft: "minutes left",
      skipToSelect: "or select it yourself >",
      skipToType: "or type it yourself >",
      dontBack: "Please avoid pressing back # or refreshing the page",
    },
    bookNew: {
      header: "Oops! Time Expired",
      text: "Sorry, you have ran out of time for making the transaction. You can try booking a ticket again.",
      would: "Would you like to book the ticket again?",
      buttons: {
        yes: "Yes",
        no: "No",
      },
      query: "Book Ticket",
    },
    back: "Go Back",
    time: "Time Left",
  },
  hi: {
    loader: {
      header: "कृपया प्रतीक्षा करें..",
      text: "भुगतान गेटवे का प्रारंभ हो रहा है",
    },
    selectMode: {
      error: "एक वैध यू.पी.आई. आईडी मैन्युअल रूप से या आवाज से दर्ज करें",
      header: "कृपया पेमेंट मोड का चयन करें",
      footer: "जारी रखने के लिए कृपया अपनी यू.पी.आई. आईडी प्रदान करें",
      placeHolder: "अपनी यू.पी.आई. आईडी दर्ज करें",
      timer: "कृपया अपना पेमेंट टाइमर से पहले पूरा करें",
      confirmUPI: "- यूपीआई आईडी की पुष्टि करें -",
      approvePayment: "- भुगतान स्वीकृत करें -",
      isCorrect: "क्या यह सही है?",
      openUPI: "भुगतान करने के लिए अपना UPI ऐप खोलें",
      edit: "यदि आवश्यक हो तो कृपया संपादित करें।",
      minLeft: "मिनट बचे हैं",
      skipToSelect: "या स्वयं चुनें >",
      skipToType: "या इसे स्वयं टाइप करें >",
      dontBack: "कृपया बैक बटन दबाने # या रिफ्रेश करने का प्रयास न करें",
    },
    bookNew: {
      header: "समय समाप्त हो गया है",
      text: "क्षमा करें, भुगतान करने के लिए आपका समय समाप्त हो गया है। आप फिर से टिकट बुक करने का प्रयास कर सकते हैं।",
      would: "क्या आप फिर से टिकट बुक करना चाहेंगे?",
      buttons: {
        yes: "हां",
        no: "नही",
      },
      query: "टिकट बुक करो",
    },
    back: "वापस जाएं",
    time: "शेष समय",
  },
  gu: {
    loader: {
      header: "કૃપા કરીને રાહ જુઓ...",
      text: "ચુકવણી ગેટવે શરૂ કરી રહ્યા છીએ",
    },
    selectMode: {
      error: "માન્ય UPI મેન્યુઅલી અથવા વૉઇસ દ્વારા ID દાખલ કરો",
      header: "કૃપા કરીને ચુકવણી મોડ પસંદ કરો",
      footer: "ચાલુ રાખવા માટે કૃપા કરીને તમારું UPI દાખલ કરો. આઈડી આપો",
      placeHolder: "તમારું UPI આઈડી દાખલ કરો",
      timer: "કૃપા કરીને ટાઈમર પહેલાં તમારી ચુકવણી પૂર્ણ કરો",
      confirmUPI: "- UPI ID કન્ફર્મ કરો -",
      approvePayment: "- ચુકવણી મંજૂર કરો -",
      isCorrect: "શુ તે સાચુ છે?",
      openUPI: "ચુકવણી કરવા માટે તમારી UPI એપ ખોલો",
      edit: "જો જરૂરી હોય તો કૃપા કરીને સંપાદિત કરો.",
      minLeft: "મિનિટ બાકી",
      skipToSelect: "અથવા તમારી જાતને પસંદ કરો >",
      skipToType: "અથવા તેને જાતે લખો >",
      dontBack:
        "કૃપા કરીને પાછળનું બટન દબાવવાનો # અથવા તાજું કરવાનો પ્રયાસ કરશો નહીં",
    },
    bookNew: {
      header: "સમય પૂરો થઈ ગયો છે.",
      text: "માફ કરશો, તમારો ચૂકવણી કરવાનો સમય સમાપ્ત થઈ ગયો છે. તમે ફરીથી ટિકિટ બુક કરવાનો પ્રયાસ કરી શકો છો.",
      would: "શું તમે ફરીથી ટિકિટ બુક કરવા માંગો છો?",
      buttons: {
        yes: "હા.",
        no: "ના.",
      },
      query: "ટિકિટ બુક કરો",
    },
    back: "પર પાછા જાઓ",
    time: "બાકીનો સમય",
  },
};

export default translations;
