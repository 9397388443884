import axios from "axios";
import { getChannel, getDSession } from "../Helpers/misc";
import store from "../Store";
import getHeaders, { isUnAuthorised } from "./getHeaders";
let cancelToken;
export const getNearbyStations = async ({ latitude, longitude }) => {
  try {
    const response = await axios.get(
      `/dishaAPI/bot/stationsByLocation/${latitude}/${longitude}`
    );

    return { data: response.data, error: false };
  } catch (e) {
    return { data: [], error: true };
  }
};

export const sendQuery = async ({
  query,
  next_context,
  langCode,
  cxpayload,
  isFallback,
}) => {
  if (cancelToken) {
    cancelToken.cancel("Operation cancelled due to new request");
  }
  cancelToken = axios.CancelToken.source();

  const configuration = store.getState().app;
  const inputMode = store.getState().behaviour.input_mode;
  let tempquery = "";
  if ((query.includes("करी") || query.includes("पशु")) && langCode == "hi") {
    tempquery = query.replace("करी", "कैरी");
    tempquery = tempquery.replace("पशु", "पालतू जानवर");
    tempquery = tempquery + "?";
  } else if (query == "Ticket Cancellation" || query == "TDR") {
    tempquery = query;
  } else {
    tempquery = query.toLowerCase();
    if (tempquery.includes("vandhe") && tempquery.includes("bharat")) {
      tempquery = tempquery.replace("vandhe", "vande");
    }
  }
  let payload = {
    query: tempquery,
    source: window.navigator.userAgent,
    inputType: inputMode,
    next_context: next_context,
    cxpayload: cxpayload,
    userToken: configuration.userToken || null,
    suggestion: false,
    isFallback: isFallback,
    isRefund: configuration.isRefund,
    channel: getChannel(),
    prevCode: configuration.prevCode,
    audioUrl: null,
    dSession: getDSession(),
    deviceId: configuration.deviceId,
    sessionId: configuration.sessionId,
    status: 1,
  };

  try {
    const response = await axios.post(
      `/dishaAPI/bot/sendQuery/${langCode}`,

      payload,
      {
        headers: getHeaders(),
        cancelToken: cancelToken.token,
        timeout: 60000,
      }
    );

    return { error: false, ...response.data };
  } catch (e) {
    await isUnAuthorised(e.response);
    return { error: true, status: e.response.status };
  }
};
